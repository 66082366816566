import React, { useCallback, useState } from "react";
import moment from "moment";
import { batch, useDispatch, useSelector } from "react-redux";
import AppStateModel from "../../models/appStateModel";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Pager from "../components/Pager";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import { SearchShipment } from "../../api/data";
import Select from "react-select";
import Card from "react-bootstrap/Card";
import Authorized from "../../components/Authorized";
import ReceiveModal from "./components/receiveModal";
import CitiesSelect from "../components/CitiesSelect";
import {
    AssignToCourierAsync,
    AssignToDriverAsync,
    CollectCodAsync, CompleteDeliveryCycleAsync,
    HoldShipmentAsync,
    MarkAsLostAsync,
    PrintLabelAsync,
    ReceiveReturnedShipmentsAsync,
    ReceiveShipmentAsync,
    ReturnShipmentsToCompanyAsync,
    searchShipmentsAsync,
    setShipmentsCriteriaAsync,
    TransferShipmentAsync,
    UpdateShipmentAsync,
} from "../../actions/shipmentsActions";
import { DateRangePicker } from "react-dates";
import NewShipment from "./components/newShipmentModal";
import ImportShipmentsModal from "./components/importShipmentModal";
import TrackShipmentModal from "./components/trackShipmentModal";
import ShipmentsTable from "./components/shipmentsTable";
import SelectCourierModal from "./components/selectCourierModal";
import MarkAsLost from "./components/markAsLost";
import AssignCourierModal from "./components/assignCourierModal";
import ReceiveReturnedModal from "./components/receiveReturnedModal";
import ReturnToCompanyModal from "./components/returnToCompanyModal";
import TransferShipmentModal from "./components/transferShipmentModal";
import CollectCodModal from "./components/collectCodModal";
import { PrepareMetaDataAsync } from "../../actions/common";
import ShipmentModel from "../../models/shipmentModel";
import UpdateShipmentModal from "./components/updateShipmentModal";
import CourierRunSheetModel from "./components/courierRunSheet";
import ScanToPrint from "./components/scanToPrint";
import LookupModel from "../../models/lookupModel";
import {
    Accountant,
    Admin,
    Cashier,
    CustomerAdmin,
    CustomerService,
    CustomerServiceManager,
    OpsManager,
    OpsOfficer,
    OpsSupervisor,
    Sales,
} from "../../models/jobRole";
import StoreShipmentModal from "./components/storeShipmentModal";
import HoldShipmentModal from "./components/HoldShipmentModal";
//@ts-ignore
import SuccessBeep from '../../res/beepsuccess.mp3';
//@ts-ignore
import FailBee from '../../res/beepfail.mp3';
import BulkScanToPrint from "./components/bulkScanToPrint";
import { BsSearch } from "react-icons/bs";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { SearchCitiesAsync } from "../../actions/citiesActions";
import ThirdShipmentPartyModal from "./components/thirdShipmentPartyModal";
import ImportShipmentsWithoutStoreModal from "../shipments/components/importShipmentWithoutStoreModal";
import ReceiveStoreModal from "../shipments/components/receiveStoreModal";
import CompleteDeliveryModal from "./components/completeDeliveryModal";
import AssignToPartnerModal from "./components/assignToPartnerModal";

type modalNames =
    | "none"
    | "newShipment"
    | "import"
    | "receive"
    | "receiveReturned"
    | "assignDriver"
    | "assignCourier"
    | "returnToCompany"
    | "collectCod"
    | "updateShipment"
    | "courierRunSheet"
    | "scanToPrint"
    | "transfer"
    | "store"
    | "hold"
    | "receiveStore"
    | "bulkScanToPrint"
    | "markAsLost"
    | "thirdPartyShipments"
    | "completeDeliveryCycle"
    | "assignToPartner"
    | "openImport";

const Page = () => {
    const content = useSelector(
        (state: AppStateModel) => state.AppContent.shipments
    );
    const pagedShipments = useSelector(
        (state: AppStateModel) => state.PagedShipments
    );
    const searchShipmentsCriteria = useSelector(
        (state: AppStateModel) => state.SearchShipmentsCriteria
    );
    const cities = useSelector((state: AppStateModel) => state.Meta?.cities);

    const [modalOn, setModalOn] = useState<modalNames>("none");
    // const profile = useSelector((state: AppStateModel) => state.Profile);
    const [searchValue, setSearchValue] = useState("");
    const [consigneeNameValue, setConsigneeNameValue] = useState("");
    const [consigneeNumberValue, setConsigneeNumberValue] = useState("");
    const [shipperName, setShipperName] = useState('')

    const [trackShipmentId, setTrackShipmentId] = useState<string | null>(null);
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const [selectedNumbers, setSelectedNumbers] = useState<string[]>([]);
    const [selectedReason, setSelectedReason] = React.useState<LookupModel | undefined>();

    const [selectedCustomer, setSelectedCustomer] = React.useState<LookupModel | undefined>();
    const [selectedStation, setSelectedStation] = React.useState<LookupModel | undefined>();
    const [selectedStatuses, setSelectedStatuses] = React.useState<LookupModel[] | undefined>([]);
    const [selectedShippingMethod, setSelectedShippingMethod] = React.useState<LookupModel | undefined>();

    const [selectedCourier, setSelectedCourier] = React.useState<LookupModel | undefined>();
    const [, setSelectedConsigneeCity] = React.useState<LookupModel | undefined>();
    const [
        selectedShipmentForEdit,
        setSelectedShipmentForEdit,
    ] = useState<ShipmentModel | null>(null);


    const meta = useSelector((state: AppStateModel) => state.Meta);
    const [dateRangeFocusedInput, setdateRangeFocusedInput] = useState<any>();
    const [ReturnDateFromFocusedInput, setReturnDateFromFocusedInput] = useState<any>();
    const [
        codDateRangeFocusedInput,
        setCodDateRangeFocusedInput,
    ] = useState<any>();
    const [ReceiveDateFromFocusedInput, setReceiveDateFromFocusedInput] =
        useState<any>();
    const [
        dateRangeDeliverFocusedInput,
        setdateRangeDeliverFocusedInput,
    ] = useState<any>();
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(
            setShipmentsCriteriaAsync({}))
        batch(() => {
            dispatch(SearchCitiesAsync());
            dispatch(PrepareMetaDataAsync());
            dispatch(searchShipmentsAsync());
        })
    }, [dispatch]);

    const exportToExcel = useCallback(async () => {
        let res = await SearchShipment(searchShipmentsCriteria, true);
        res.succeeded && window.open(res.data.location, "_blank", "noreferrer");
    }, [searchShipmentsCriteria]);

    const selectId = useCallback((value: string, shipmentNumber?: string) => {
        if (selectedIds.length === 0) {
            setSelectedIds([value]);
            !!shipmentNumber && setSelectedNumbers([shipmentNumber])
        } else {
            if (selectedIds.find((i) => i === value)) {
                let arr = [...selectedIds];
                let i = arr.indexOf(value);
                if (i >= 0) {
                    arr.splice(i, 1);
                }
                setSelectedIds(arr);
            } else {
                setSelectedIds([...selectedIds, value]);
            }
            if (!!shipmentNumber && selectedNumbers.find((num: string) => num == shipmentNumber)) {
                let arr = [...selectedNumbers];
                let i = arr.indexOf(shipmentNumber);
                if (i >= 0) {
                    arr.splice(i, 1);
                }
                setSelectedNumbers(arr);
            } else {
                !!shipmentNumber && setSelectedNumbers([...selectedNumbers, shipmentNumber]);
            }
        }
    }, [selectedIds]);

    const selectAll = useCallback(() => {
        if (selectedIds.length === pagedShipments?.matches?.length) {
            setSelectedIds([]);
            setSelectedNumbers([])
        } else {
            let newArr: string[] = [];
            let numbers: string[] = [];
            pagedShipments.matches.forEach((ship: ShipmentModel) => {
                newArr.push(ship.id)
                numbers.push(ship.number)
            });
            setSelectedIds(newArr);
            setSelectedNumbers(numbers)
        }
    }, [pagedShipments?.matches, selectedIds.length, selectedNumbers.length]);

    const resolveStatus = useCallback((status: any) => {
        return meta?.shipmentStatuses?.find((s) => s.value === status)?.label;
    }, [meta?.shipmentStatuses]);
    const resolveCourier = useCallback((id: string) => {
        const st = meta?.couriers?.find((s) => s.value === id);
        return st?.label;
    }, [meta?.couriers]);
    const selectedIdCheck = useCallback(() => {
        return selectedIds.length !== 0;
    }, [selectedIds]);
    const refreshAfterAction = useCallback(() => {
        dispatch(searchShipmentsAsync());
        setSelectedIds([]);
        setSelectedNumbers([]);
        setModalOn("none");
    }, [dispatch]);

    const onClear = useCallback(() => {
        dispatch(
            setShipmentsCriteriaAsync({
                pageNumber: 1,
                pageSize: 20,
                search: "",
                stationId: 0,
                shipperName: "",
                numberContains: "",
                referenceContains: "",
                consigneeNumber: "",
                consigneeName: "",
                toCity: "",
                cod: "",
                consigneeCity: undefined,
                courier: undefined,
                CustomerId: undefined,
                driver: undefined,
                statuses: undefined,
                codCollectionDateFrom: undefined,
                codCollectionDateTo: undefined,
                deliveredFrom: undefined,
                deliveredTo: undefined,
                createdTo: undefined,
                createdFrom: undefined,
                ReturnDateFrom: undefined,
                ReturnDateTo: undefined,
                ReceivedDateFrom: undefined,
                ReceivedDateTo: undefined,
                statusReason: "",
                isNotConfirmedCustomer: undefined
            })
        );

        setShipperName('')
        setSelectedStation(undefined);
        setSelectedCustomer(undefined);
        setSelectedCourier(undefined);
        setSelectedConsigneeCity(undefined);
        setSelectedStatuses(undefined);
        setSelectedReason(undefined)
        setSelectedShippingMethod(undefined);
        setSearchValue("");
        setConsigneeNameValue("");
        setConsigneeNumberValue("");
        setSelectedIds([]);
        setSelectedNumbers([]);
        setSelectedShipmentForEdit(null);
        setdateRangeFocusedInput({});
        // dispatch(searchShipmentsAsync())
    }, []);

    const handleKeyPress = (event: any, callback: () => any) => {
        if (event.key === "Enter") {
            callback();
        }
    };

    const renderModal = useCallback((val: modalNames) => {
        switch (val) {
            case "newShipment":
                return (
                    <NewShipment
                        visible={true}
                        onHide={() => {
                            dispatch(searchShipmentsAsync());
                            setModalOn("none");
                        }}
                    />
                );
            case "import":
                return (
                    <ImportShipmentsModal
                        visible={true}
                        onHide={() => setModalOn("none")}
                    />
                );
            case "openImport":
                return (
                    <ImportShipmentsWithoutStoreModal
                        visible={true}
                        onHide={() => setModalOn("none")}
                    />
                );
            case "assignCourier":
                return (
                    <AssignCourierModal
                        onHide={() => setModalOn("none")}
                        visible={true}
                        onDone={(ids, courierId) => {
                            dispatch(
                                AssignToCourierAsync(ids, courierId, () => refreshAfterAction())
                            );
                        }}
                        title={content.assignCourierButton}
                        submitButton={content.assignCourierButton}
                        allowedStatuses={["20", "23"]}
                    />
                );
            case "completeDeliveryCycle":
                return (
                    <CompleteDeliveryModal
                        onHide={() => setModalOn("none")}
                        visible={true}
                        onDone={(ids, courierId) => {
                            dispatch(
                                CompleteDeliveryCycleAsync(ids, courierId, () => refreshAfterAction())
                            );
                        }}
                    />
                );
            case "assignDriver":
                if (selectedIdCheck()) {
                    return (
                        <SelectCourierModal
                            visible={true}
                            onHide={() => setModalOn("none")}
                            onDone={(courierId) => {
                                dispatch(
                                    AssignToDriverAsync(selectedIds, courierId, () =>
                                        refreshAfterAction()
                                    )
                                );
                            }}
                            options={meta?.couriers}
                        />
                    );
                } else return <></>;
            case "markAsLost":
                if (selectedIdCheck()) {
                    return (
                        <MarkAsLost
                            visible={true}
                            onHide={() => setModalOn("none")}
                            onDone={(reason) => {
                                dispatch(
                                    MarkAsLostAsync(selectedIds, reason, () =>
                                        refreshAfterAction()
                                    )
                                );
                            }}
                        />
                    );
                } else return <></>;

            case "receive":
                return (
                    <ReceiveModal
                        visible={true}
                        onHide={() => setModalOn("none")}
                        onDone={(ids: any, station: any) => {
                            dispatch(
                                ReceiveShipmentAsync(ids, station, () => refreshAfterAction())
                            );
                        }}
                        title={content.receiveButton}
                        submitButton={content.receiveButton}
                        allowedStatuses={["80", "10", "12", "82", "40", "26", "68", "84"]}
                    />
                );
            case "receiveReturned":
                return (
                    <ReceiveReturnedModal
                        visible={true}
                        onHide={() => setModalOn("none")}
                        title={content.receiveReturnedButton}
                        submitButton={content.receiveReturnedButton}
                        changeButton={content.codCollect.change}
                        onDone={(ids: any, station: any) => {
                            dispatch(
                                ReceiveReturnedShipmentsAsync(ids, station, () => refreshAfterAction())
                            );
                        }}
                        allowedStatuses={["40"]}
                    />
                );
            case "returnToCompany":
                return (
                    <ReturnToCompanyModal
                        visible={true}
                        onHide={() => setModalOn("none")}
                        onDone={(ids, driverId) => {
                            dispatch(
                                ReturnShipmentsToCompanyAsync(ids, driverId, () =>
                                    refreshAfterAction()
                                )
                            );
                        }}
                        allowedStatuses={["20", "15", "50", "26"]}
                    />
                );
            case "collectCod":
                return (
                    <CollectCodModal
                        visible={true}
                        onHide={() => setModalOn("none")}
                        title={content.codCollect.title}
                        submitButton={content.codCollect.submit}
                        onDone={(ids: any, courierId: string) => {
                            dispatch(
                                CollectCodAsync(ids, courierId, () => refreshAfterAction())
                            );
                        }}
                    />
                );
            case "updateShipment":
                return (
                    <UpdateShipmentModal
                        visible={true}
                        onHide={() => {
                            refreshAfterAction()
                            setSelectedShipmentForEdit(null);
                            setModalOn("none");
                        }}
                        title={content.updateShipment.title}
                        submitButton={content.updateShipment.submit}
                        shipment={selectedShipmentForEdit!}
                        onDone={(update: any) => {
                            dispatch(UpdateShipmentAsync(update, () => refreshAfterAction()));
                        }}
                    />
                );
            case "courierRunSheet":
                return (
                    <CourierRunSheetModel
                        visible={true}
                        onHide={() => setModalOn("none")}
                        title={content.courierRunSheetButton}
                    />
                );
            case "transfer":
                return (
                    <TransferShipmentModal
                        flag={'AssignForTransfer'}
                        visible={true}
                        onHide={() => setModalOn("none")}
                        onDone={(ids: any, station: any, courierId: string) => {
                            dispatch(
                                TransferShipmentAsync(ids, station, courierId, () => refreshAfterAction())
                            );
                        }}
                        title={content.transferButton}
                        submitButton={content.receiveButton}
                        allowedStatuses={["20", "15", "50"]}
                    />
                );
            case "store":
                return (
                    <StoreShipmentModal
                        visible={true}
                        onHide={() => {
                            refreshAfterAction();
                            setModalOn("none")
                        }}
                        title={"Store"}
                        submitButton={"Store"}
                    />
                );
            case "receiveStore":
                return (
                    <ReceiveStoreModal
                        visible={true}
                        onHide={() => {
                            refreshAfterAction();
                            setModalOn("none")
                        }}
                        title={"Receive Store"}
                        submitButton={"Store"}
                    />
                );

            case "hold":
                return (
                    <HoldShipmentModal
                        visible={true}
                        onHide={() => setModalOn("none")}
                        onDone={(ids: string[], reason: string, location: string) => {
                            dispatch(
                                HoldShipmentAsync(ids, reason, location, () => refreshAfterAction())
                            );
                        }}
                        title={"Hold"}
                        submitButton={"Hold"}
                    />
                );
            case "scanToPrint":
                return <ScanToPrint visible={true}
                    onDone={() => setModalOn("none")} />;

            case "bulkScanToPrint":
                return <BulkScanToPrint visible={true}
                    onDone={() => setModalOn("none")} 
                    selectedShipments={selectedNumbers ?? []}    
                />;
            case "thirdPartyShipments":
                return <ThirdShipmentPartyModal visible={true}
                    onDone={() => setModalOn("none")} />;
            case "assignToPartner":
                return <AssignToPartnerModal visible={true}
                                                onDone={() => setModalOn("none")} />;
            default:
                return <></>;
        }
    }, [selectedShipmentForEdit?.id, selectedIds, selectedNumbers]);

    const renderActionButton = (label: string, onPress: () => any) => {
        return (
            <Button aria-label={label} onClick={() => onPress()} variant="light">
                {label}
            </Button>
        );
    };

    return (
        <div style={{ padding: "1vw" }}>
            {renderModal(modalOn)}

            {trackShipmentId !== null && (
                <TrackShipmentModal
                    visible={true}
                    onHide={() => setTrackShipmentId(null)}
                    shipmentId={trackShipmentId ?? ""}
                />
            )}
            <Card>
                <Card.Header>
                    <Row>
                        <Authorized
                            allowedRoles={[
                                Admin.label,
                                OpsManager.label,
                                OpsOfficer.label,
                                OpsSupervisor.label,
                                Accountant.label,
                                Cashier.label,
                                CustomerServiceManager.label,
                                CustomerService.label,
                                Sales.label,
                            ]}
                        >
                            <Col lg={2} className="px-md-4">
                                <Select
                                    key={`selectedCustomer__${selectedCustomer}`}
                                    value={selectedCustomer}
                                    styles={{ control: customControlStyles }}
                                    options={meta?.customers}
                                    isClearable
                                    placeholder={content.model.customerName}
                                    onChange={(selectedOption) => {
                                        if (Array.isArray(selectedOption)) {
                                            throw new Error(
                                                "Unexpected type passed to ReactSelect onChange handler"
                                            );
                                        }

                                        setSelectedCustomer({
                                            label: selectedOption?.label!,
                                            value: selectedOption?.value!,
                                        });

                                        dispatch(
                                            //@ts-ignore
                                            setShipmentsCriteriaAsync({
                                                //@ts-ignore
                                                CustomerId: selectedOption?.value,
                                            })
                                        );
                                    }}
                                />
                            </Col>
                        </Authorized>
                        <Col lg={2} className="px-md-4">
                            <Select
                                key={`selectedReasonX_${selectedReason}`}
                                value={selectedReason}
                                className="basic-multi-select"
                                isClearable
                                options={meta?.statusReasons}
                                placeholder={"Reason"}
                                onChange={(selectedOption) => {
                                    setSelectedReason(selectedOption ?? undefined)
                                    dispatch(
                                        setShipmentsCriteriaAsync({
                                            statusReason: selectedOption?.value,
                                        })
                                    )


                                }}
                            />
                        </Col>
                        <Col lg={2} className="px-md-4">
                            <Select
                                isMulti
                                value={selectedStatuses}
                                key={`selectedStatuses${selectedStatuses}`}
                                className="basic-multi-select"
                                escapeClearsValue={true}
                                options={meta?.shipmentStatuses}
                                placeholder={content.model.status}
                                onChange={(selectedOptions) => {
                                    let statuses: string[] = [];
                                    let statusModel: LookupModel[] = [];
                                    if (Array.isArray(selectedOptions)) {
                                        selectedOptions?.forEach((element: any) => {
                                            statuses.push(element.value);

                                            statusModel.push({
                                                value: element.value,
                                                label: element.label,
                                            });
                                        });
                                    }
                                    setSelectedStatuses(statusModel);
                                    dispatch(
                                        setShipmentsCriteriaAsync({
                                            statuses: statuses,
                                        })
                                    );
                                }}
                            />
                        </Col>
                        <Col lg={2} className="px-md-4">
                            <Select
                                value={selectedShippingMethod}
                                key={`selectedShippingMethod${selectedShippingMethod}`}
                                className="basic-multi-select"
                                escapeClearsValue={true}
                                options={meta?.shippingMethods ?? []}
                                placeholder={content.model.shippingMethod}
                                onChange={(selectedOptions) => {                                    
                                    setSelectedShippingMethod(selectedOptions ?? undefined);
                                    dispatch(
                                        setShipmentsCriteriaAsync({
                                            shippingMethod: Number(selectedOptions?.value),
                                        })
                                    );
                                }}
                            />
                        </Col>
                        <Authorized
                            allowedRoles={[
                                Admin.label,
                                OpsManager.label,
                                OpsOfficer.label,
                                OpsSupervisor.label,
                                Accountant.label,
                                Cashier.label,
                                CustomerServiceManager.label,
                                CustomerService.label,
                                Sales.label,
                            ]}
                        >
                            <Col lg={2} className="px-md-4">
                                <Select
                                    key={`selectedCourier${selectedCourier}`}
                                    onChange={(selectedOption) => {
                                        if (Array.isArray(selectedOption)) {
                                            throw new Error(
                                                "Unexpected type passed to ReactSelect onChange handler"
                                            );
                                        }
                                        setSelectedCourier({
                                            value: selectedOption?.value!,
                                            label: selectedOption?.label!,
                                        });
                                        dispatch(
                                            //@ts-ignore
                                            setShipmentsCriteriaAsync({
                                                //@ts-ignore
                                                courier: selectedOption?.value,
                                            })
                                        );
                                    }}
                                    isClearable
                                    value={selectedCourier}
                                    options={meta?.couriers}
                                    placeholder="Courier"
                                />
                            </Col>
                        </Authorized>

                        <Authorized
                            allowedRoles={[
                                Admin.label,
                                OpsManager.label,
                                OpsOfficer.label,
                                OpsSupervisor.label,
                                Accountant.label,
                                Cashier.label,
                                CustomerServiceManager.label,
                                CustomerService.label,
                                Sales.label,
                            ]}
                        >
                            <Col lg={2} className="px-md-4">
                                <Select
                                    key={`selectedStation__${selectedStation}`}
                                    value={selectedStation}
                                    styles={{ control: customControlStyles }}
                                    options={meta?.stations}
                                    isClearable
                                    placeholder={content.model.stationId}
                                    onChange={(selectedOption) => {
                                        if (Array.isArray(selectedOption)) {
                                            throw new Error(
                                                "Unexpected type passed to ReactSelect onChange handler"
                                            );
                                        }

                                        setSelectedStation({
                                            label: selectedOption?.label!,
                                            value: selectedOption?.value!,
                                        });

                                        dispatch(
                                            //@ts-ignore
                                            setShipmentsCriteriaAsync({
                                                //@ts-ignore
                                                stationId: selectedOption?.value,
                                            })
                                        );
                                    }}
                                />
                            </Col>
                        </Authorized>

                        <Col lg={2} className="px-md-4">
                            <CitiesSelect
                                value={String(searchShipmentsCriteria?.consigneeCity) ?? ""}
                                onChange={(val: any) => {
                                    dispatch(
                                        setShipmentsCriteriaAsync({
                                            ...searchShipmentsCriteria,
                                            consigneeCity: cities?.find((c) => c.label == val)?.label,
                                        })
                                    );
                                    dispatch(searchShipmentsAsync());
                                }}
                                placeholder={content.consigneeCity}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={2} className="px-md-4 mb-3">
                            <div style={{
                                height: 37,
                                backgroundColor: "white",
                                borderWidth: "1px",
                                borderStyle: "solid",
                                borderColor: "lightgrey",
                                borderRadius: "3px"
                            }}>
                                <DateRangePicker
                                    noBorder={true}
                                    isOutsideRange={() => false}
                                    small
                                    isDayBlocked={() => false}
                                    minimumNights={0}
                                    startDatePlaceholderText="Created From"
                                    endDatePlaceholderText="Created To"
                                    startDate={
                                        searchShipmentsCriteria.createdFrom
                                            ? moment(searchShipmentsCriteria.createdFrom)
                                            : null
                                    }
                                    startDateId="123" // PropTypes.string.isRequired,
                                    endDate={
                                        searchShipmentsCriteria.createdTo
                                            ? moment(searchShipmentsCriteria.createdTo)
                                            : null
                                    }
                                    endDateId="321" // PropTypes.string.isRequired,
                                    onDatesChange={({ startDate, endDate }) => {
                                        dispatch(
                                            setShipmentsCriteriaAsync({
                                                createdFrom: startDate?.toDate(),
                                                createdTo: endDate?.toDate(),
                                            })
                                        );
                                    }}
                                    focusedInput={dateRangeFocusedInput}
                                    onFocusChange={(focusedInput) => {
                                        setdateRangeFocusedInput(focusedInput);
                                    }}
                                />
                            </div>
                        </Col>
                        <Col lg={2} className="px-md-4 mb-3">
                            <div style={{
                                height: 37,
                                backgroundColor: "white",
                                borderWidth: "1px",
                                borderStyle: "solid",
                                borderColor: "lightgrey",
                                borderRadius: "3px"
                            }}>
                                <DateRangePicker
                                    noBorder={true}
                                    isOutsideRange={() => false}
                                    small
                                    minimumNights={0}
                                    startDatePlaceholderText="Deliver From"
                                    endDatePlaceholderText="Deliver To"
                                    startDate={
                                        searchShipmentsCriteria.deliveredFrom
                                            ? moment(searchShipmentsCriteria.deliveredFrom)
                                            : null
                                    }
                                    startDateId="123" // PropTypes.string.isRequired,
                                    endDate={
                                        searchShipmentsCriteria.deliveredTo
                                            ? moment(searchShipmentsCriteria.deliveredTo)
                                            : null
                                    }
                                    endDateId="321" // PropTypes.string.isRequired,
                                    onDatesChange={({ startDate, endDate }) => {
                                        dispatch(
                                            setShipmentsCriteriaAsync({
                                                deliveredFrom: startDate?.toDate(),
                                                deliveredTo: endDate?.toDate(),
                                            })
                                        );
                                    }}
                                    focusedInput={dateRangeDeliverFocusedInput}
                                    onFocusChange={(focusedInput) => {
                                        setdateRangeDeliverFocusedInput(focusedInput);
                                    }}
                                />
                            </div>
                        </Col>
                        <Col lg={2} className="px-md-4 mb-3">
                            <div style={{
                                height: 37,
                                backgroundColor: "white",
                                borderWidth: "1px",
                                borderStyle: "solid",
                                borderColor: "lightgrey",
                                borderRadius: "3px"
                            }}>
                                <DateRangePicker
                                    noBorder={true}
                                    isOutsideRange={() => false}
                                    small
                                    minimumNights={0}
                                    startDatePlaceholderText="Received from"
                                    endDatePlaceholderText="Received To"
                                    startDate={
                                        searchShipmentsCriteria.ReceivedDateFrom
                                            ? moment(searchShipmentsCriteria.ReceivedDateFrom)
                                            : null
                                    }
                                    startDateId="123" // PropTypes.string.isRequired,
                                    endDate={
                                        searchShipmentsCriteria.ReceivedDateTo
                                            ? moment(searchShipmentsCriteria.ReceivedDateTo)
                                            : null
                                    }
                                    endDateId="321" // PropTypes.string.isRequired,
                                    onDatesChange={({ startDate, endDate }) => {
                                        dispatch(
                                            setShipmentsCriteriaAsync({
                                                ReceivedDateFrom: startDate?.toDate(),
                                                ReceivedDateTo: endDate?.toDate(),
                                            })
                                        );
                                    }}
                                    focusedInput={ReceiveDateFromFocusedInput}
                                    onFocusChange={(focusedInput) => {
                                        setReceiveDateFromFocusedInput(focusedInput);
                                    }}
                                />
                            </div>
                        </Col>
                        <Col lg={2} className="px-md-4 mb-3">
                            <div style={{
                                height: 37,
                                backgroundColor: "white",
                                borderWidth: "1px",
                                borderStyle: "solid",
                                borderColor: "lightgrey",
                                borderRadius: "3px"
                            }}>
                                <DateRangePicker
                                    noBorder={true}
                                    isOutsideRange={() => false}
                                    small
                                    isDayBlocked={() => false}
                                    minimumNights={0}
                                    startDatePlaceholderText="Cod date from"
                                    endDatePlaceholderText="Cod date to"
                                    startDate={
                                        searchShipmentsCriteria.codCollectionDateFrom
                                            ? moment(searchShipmentsCriteria.codCollectionDateFrom)
                                            : null
                                    }
                                    startDateId="123" // PropTypes.string.isRequired,
                                    endDate={
                                        searchShipmentsCriteria.codCollectionDateTo
                                            ? moment(searchShipmentsCriteria.codCollectionDateTo)
                                            : null
                                    }
                                    endDateId="321" // PropTypes.string.isRequired,
                                    onDatesChange={({ startDate, endDate }) => {
                                        dispatch(
                                            setShipmentsCriteriaAsync({
                                                codCollectionDateFrom: startDate?.toDate(),
                                                codCollectionDateTo: endDate?.toDate(),
                                            })
                                        );
                                    }}
                                    focusedInput={codDateRangeFocusedInput}
                                    onFocusChange={(focusedInput) => {
                                        setCodDateRangeFocusedInput(focusedInput);
                                    }}
                                />
                            </div>
                        </Col>
                        <Col lg={2} className="px-md-4 mb-3">
                            <div style={{
                                height: 37,
                                backgroundColor: "white",
                                borderWidth: "1px",
                                borderStyle: "solid",
                                borderColor: "lightgrey",
                                borderRadius: "3px"
                            }}>
                                <DateRangePicker
                                    noBorder={true}
                                    isOutsideRange={() => false}
                                    small
                                    minimumNights={0}
                                    startDatePlaceholderText="Return from"
                                    endDatePlaceholderText="Return To"
                                    startDate={
                                        searchShipmentsCriteria.ReturnDateFrom
                                            ? moment(searchShipmentsCriteria.ReturnDateFrom)
                                            : null
                                    }
                                    startDateId="123" // PropTypes.string.isRequired,
                                    endDate={
                                        searchShipmentsCriteria.ReturnDateTo
                                            ? moment(searchShipmentsCriteria.ReturnDateTo)
                                            : null
                                    }
                                    endDateId="321" // PropTypes.string.isRequired,
                                    onDatesChange={({ startDate, endDate }) => {
                                        dispatch(
                                            setShipmentsCriteriaAsync({
                                                ReturnDateFrom: startDate?.toDate(),
                                                ReturnDateTo: endDate?.toDate(),
                                            })
                                        );
                                    }}
                                    focusedInput={ReturnDateFromFocusedInput}
                                    onFocusChange={(focusedInput) => {
                                        setReturnDateFromFocusedInput(focusedInput);
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={2} className="px-md-4">
                            <InputGroup className="mb-3">
                                <Form.Control
                                    style={{ height: 35 }}
                                    // size="sm"
                                    placeholder={content.search}
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                    onKeyPress={(e: any) =>
                                        handleKeyPress(e, () =>
                                            dispatch(
                                                setShipmentsCriteriaAsync({
                                                    search: searchValue,
                                                })
                                            )
                                        )
                                    }
                                />
                                <InputGroup.Append style={{ zIndex: 0 }}>
                                    <Button
                                        onClick={() =>
                                            dispatch(
                                                setShipmentsCriteriaAsync({
                                                    search: searchValue,
                                                })
                                            )
                                        }
                                        className="icon-button"
                                    >
                                        <BsSearch name={"search"} color={"white"}
                                            size={21} />
                                    </Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>
                        <Col lg={2} className="px-md-4">
                            <InputGroup className="mb-3">
                                <Form.Control
                                    style={{ height: 35 }}
                                    placeholder={content.consigneeNameSearch}
                                    value={consigneeNameValue}
                                    onChange={(e) => setConsigneeNameValue(e.target.value)}
                                    onKeyPress={(e: any) =>
                                        handleKeyPress(e, () =>
                                            dispatch(
                                                setShipmentsCriteriaAsync({
                                                    consigneeName: consigneeNameValue,
                                                })
                                            )
                                        )
                                    }
                                />
                                <InputGroup.Append style={{ zIndex: 0 }}>
                                    <Button
                                        onClick={() =>
                                            dispatch(
                                                setShipmentsCriteriaAsync({
                                                    consigneeName: consigneeNameValue,
                                                })
                                            )
                                        }
                                        className="icon-button"
                                    >
                                        <BsSearch name={"search"} color={"white"}
                                            size={21} />
                                    </Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>
                        <Col lg={2} className="px-md-4">
                            <InputGroup className="mb-3">
                                <Form.Control
                                    style={{ height: 35 }}
                                    placeholder={'Shipper Name'}
                                    value={shipperName}
                                    onChange={(e) => setShipperName(e.target.value)}
                                    onKeyPress={(e: any) =>
                                        handleKeyPress(e, () =>
                                            dispatch(
                                                setShipmentsCriteriaAsync({
                                                    shipperName: shipperName,
                                                })
                                            )
                                        )
                                    }
                                />
                                <InputGroup.Append style={{ zIndex: 0 }}>
                                    <Button
                                        onClick={() =>
                                            dispatch(
                                                setShipmentsCriteriaAsync({
                                                    shipperName: shipperName,
                                                })
                                            )
                                        }
                                        className="icon-button"
                                    >
                                        <BsSearch name={"search"} color={"white"}
                                            size={21} />
                                    </Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>

                        <Col lg={2} className="px-md-4">
                            <InputGroup className="mb-3">
                                <Form.Control
                                    style={{ height: 35 }}
                                    placeholder={content.consigneeNumberSearch}
                                    value={consigneeNumberValue}
                                    onChange={(e) => setConsigneeNumberValue(e.target.value)}
                                    onKeyPress={(e: any) =>
                                        handleKeyPress(e, () =>
                                            dispatch(
                                                setShipmentsCriteriaAsync({
                                                    consigneeNumber: consigneeNumberValue,
                                                })
                                            )
                                        )
                                    }
                                />
                                <InputGroup.Append style={{ zIndex: 0 }}>
                                    <Button
                                        onClick={() =>
                                            dispatch(
                                                setShipmentsCriteriaAsync({
                                                    consigneeNumber: consigneeNumberValue,
                                                })
                                            )
                                        }
                                        className="icon-button"
                                    >
                                        <BsSearch name={"search"} color={"white"}
                                            size={21} />
                                    </Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>
                        <Col md={2}>
                            <InputGroup className="mb-3 px-4 border">
                                <Form.Check size={50} className="px-1"
                                    type="checkbox"
                                    checked={searchShipmentsCriteria.isNotConfirmedCustomer ?? false}
                                    onChange={(e) => dispatch(
                                        setShipmentsCriteriaAsync({
                                            isNotConfirmedCustomer: e.target.checked,
                                        })
                                    )} />
                                <Form.Text>
                                    From unconfirmed companies
                                </Form.Text>
                            </InputGroup>
                        </Col>

                    </Row>
                    <Row>
                        <Col lg={1.5} className="px-md-4">
                            <Button
                                variant="danger"
                                onClick={() => {
                                    onClear();
                                }}
                            >
                                Clear
                            </Button>
                        </Col>
                        <Col>
                            <div style={{
                                display: "flex",
                                justifyContent: "flex-end"
                            }}>
                                <ButtonGroup>
                                    <Authorized
                                        allowedRoles={[
                                            Admin.label,
                                        ]}
                                    >
                                    {renderActionButton("Assign To Partner", () =>
                                        setModalOn("assignToPartner")
                                    )}
                                    </Authorized>
                                    {renderActionButton("Third Party Shipments", () =>
                                        setModalOn("thirdPartyShipments")
                                    )}
                                    {renderActionButton("Scan To Print", () =>
                                        setModalOn("scanToPrint")
                                    )}
                                    {renderActionButton("Bulk Scan To Print", () =>
                                        setModalOn("bulkScanToPrint")
                                    )}
                                    {selectedIds.length == 0 && (
                                        <>
                                            <Authorized
                                                allowedRoles={[
                                                    Admin.label,
                                                    OpsManager.label,
                                                    OpsSupervisor.label,
                                                    OpsOfficer.label,
                                                ]}
                                            >
                                                {renderActionButton(content.transferButton, () =>
                                                    setModalOn("transfer")
                                                )}
                                            </Authorized>
                                            <Authorized
                                                allowedRoles={[
                                                    Admin.label,
                                                ]}
                                            >
                                                {renderActionButton(content.completeDeliveryCycle, () =>
                                                    setModalOn("completeDeliveryCycle")
                                                )}
                                            </Authorized>
                                            <Authorized
                                                allowedRoles={[
                                                    Admin.label,
                                                    OpsManager.label,
                                                    OpsSupervisor.label,
                                                    Cashier.label,
                                                ]}
                                            >
                                                {renderActionButton(content.courierRunSheetButton, () =>
                                                    setModalOn("courierRunSheet")
                                                )}
                                            </Authorized>
                                            <Authorized
                                                allowedRoles={[
                                                    Admin.label,
                                                    Cashier.label,
                                                    OpsManager.label,
                                                ]}
                                            >
                                                {renderActionButton(content.collectCodButton, () =>
                                                    setModalOn("collectCod")
                                                )}
                                            </Authorized>
                                            <Authorized
                                                allowedRoles={[
                                                    Admin.label,
                                                    OpsOfficer.label,
                                                    OpsManager.label,
                                                    OpsSupervisor.label,
                                                ]}
                                            >
                                                {renderActionButton(
                                                    content.returnToCustomerButton,
                                                    () => setModalOn("returnToCompany")
                                                )}
                                                {renderActionButton(content.receiveReturnedButton, () =>
                                                    setModalOn("receiveReturned")
                                                )}

                                                {renderActionButton(content.receiveButton, () =>
                                                    setModalOn("receive")
                                                )}
                                                {renderActionButton('Store', () =>
                                                    setModalOn("store")
                                                )}
                                                {renderActionButton('Receive Store', () =>
                                                    setModalOn("receiveStore")
                                                )}
                                                {renderActionButton('Hold', () =>
                                                    setModalOn("hold")
                                                )}

                                                {renderActionButton(content.assignCourierButton, () =>
                                                    setModalOn("assignCourier")
                                                )}
                                            </Authorized>
                                        </>
                                    )}
                                    {selectedIds.length > 0 && (
                                        <Authorized
                                            allowedRoles={[
                                                Admin.label,
                                                OpsOfficer.label,
                                                OpsManager.label,
                                                OpsSupervisor.label,
                                            ]}
                                        >
                                            {renderActionButton(content.assignDriverButton, () =>
                                                setModalOn("assignDriver")
                                            )}
                                        </Authorized>
                                    )}
                                    {selectedIds.length > 0 && (
                                        <Authorized
                                            allowedRoles={[
                                                Admin.label,
                                            ]}
                                        >
                                            {renderActionButton(content.markAsLost, () =>
                                                setModalOn("markAsLost")
                                            )}
                                        </Authorized>
                                    )}
                                    <Authorized
                                        allowedRoles={[
                                            Admin.label,
                                            OpsManager.label,
                                            OpsSupervisor.label,
                                            OpsOfficer.label,
                                            CustomerService.label,
                                            Cashier.label,
                                            Sales.label,
                                            Accountant.label,
                                            CustomerAdmin.label,
                                            CustomerServiceManager.label,
                                        ]}
                                    >
                                        <Button
                                            aria-label={content.export}
                                            onClick={() => exportToExcel()}
                                            variant="light"
                                        >
                                            <AiOutlineCloudDownload
                                                color={"black"} size={21} />
                                        </Button>
                                    </Authorized>
                                    <Authorized
                                        allowedRoles={[
                                            Admin.label,
                                            OpsOfficer.label,
                                            OpsManager.label,
                                            OpsSupervisor.label,
                                        ]}
                                    >
                                        {renderActionButton(content.new, () =>
                                            setModalOn("newShipment")
                                        )}
                                    </Authorized>
                                    <Authorized
                                        allowedRoles={[CustomerAdmin.label]}>
                                        <DropdownButton
                                            as={ButtonGroup}
                                            title={content.add}
                                            id="bg-nested-dropdown"
                                            variant="light"
                                        >
                                            <Dropdown.Item
                                                eventKey="1"
                                                onClick={() => setModalOn("newShipment")}
                                            >
                                                {content.new}
                                            </Dropdown.Item>

                                            <Dropdown.Item
                                                eventKey="2"
                                                onClick={() => setModalOn("import")}
                                            >
                                                {content.import}
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                eventKey="2"
                                                onClick={() => setModalOn("openImport")}
                                            >
                                                {content.importWithoutStore}
                                            </Dropdown.Item>

                                        </DropdownButton>
                                    </Authorized>
                                </ButtonGroup>
                            </div>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>

                    <ShipmentsTable
                        onSelectAll={selectAll}
                        onSelectId={selectId}
                        pagedShipments={pagedShipments}
                        printLabel={(id: string) => {
                            dispatch(PrintLabelAsync(id));
                        }}
                        resolveStatus={resolveStatus}
                        selectedIds={selectedIds} 
                        setTrackShipmentId={setTrackShipmentId}
                        resolveCouriers={resolveCourier}
                        editShipment={(shipmentData: ShipmentModel) => {
                            setSelectedShipmentForEdit(shipmentData);
                            setModalOn("updateShipment");
                        }}
                    />

                    {pagedShipments && pagedShipments?.total > 0 && (
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Pager
                                currentPageNumber={searchShipmentsCriteria.pageNumber!}
                                numberOfPages={Math.ceil(
                                    pagedShipments.total! / searchShipmentsCriteria.pageSize!
                                )}
                                onChange={(page: number) =>
                                    dispatch(
                                        setShipmentsCriteriaAsync({
                                            ...searchShipmentsCriteria,
                                            pageNumber: page,
                                        })
                                    )
                                }
                            />
                        </div>
                    )}
                </Card.Body>
            </Card>
        </div>
    );
};

const customControlStyles = (base: any) => ({
    ...base,
    height: 21!,
});


export default React.memo(Page);
