import React from "react";
import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";
import { Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { DeliverToHome } from "../../api/data";
import { SetAlert } from "../../actions/common";
import { useDispatch } from "react-redux";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const DeliverToHomeModal = ({ onClose, isOpen }: IProps) => {
  const location = useLocation();
  const dispatch = useDispatch();
  
  const getShipmentId = () => {
    const params = new URLSearchParams(location.search);
    return params.get("shipmentId") ?? "";
  };
  const handleSubmit = async () => {
    const shipmentId = getShipmentId();
    const res = await DeliverToHome({ shipmentId });

    if (res.succeeded) {
      dispatch(
        SetAlert({
          visible: true,
          data: "",
          title: "Success",
          kind: "success",
        })
      );
    } else {
      dispatch(
        SetAlert({
          visible: true,
          data: res.data,
          title: "Error",
          kind: "error",
        })
      );
    }
    
    onClose();
  };

  return (
    <div>
      <Modal
        show={isOpen}
        onHide={onClose}
        dialogClassName="modal-100w"
        aria-labelledby="example-custom-modal-styling-title"
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            {"Confirm"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card>
            <Card.Body>
              {"Are You Sure You Want to Deliver to home ?"}
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleSubmit}>{"Submit"}</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default DeliverToHomeModal;
