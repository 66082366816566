import React, { useEffect, useState } from "react";
import { GoogleMap, Libraries, useLoadScript } from "@react-google-maps/api";
import mapStyle from "./mapStyle";
import { GoogleMapsApiKey } from "../../env";

type StyledMapProps = {
  children?: JSX.Element;
  centroid?: { lat: number; lng: number };
  zoomed?: number;
  handleMapClick?: (e: google.maps.MapMouseEvent) => void;
  data?: any;
  mapContainerHeight?: string
};

const libraries: Libraries = ["places"];

const StyledMap = (props: StyledMapProps) => {
  const {
    children,
    handleMapClick,
    zoomed = 12,
    centroid = {
      lat: 24.7136, // Riyadh latitude
      lng: 46.6753, // Riyadh longitude
    },
    data,
  } = props;
  const [renderMap, setRenderMap] = useState(false);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GoogleMapsApiKey,
    libraries: libraries,
  });

  const mapContainerStyle = {
    width: "100%",
    height: props.mapContainerHeight ?? "91%",
  };

  const options = {
    styles: mapStyle,
    disableDefaultUI: true,
    zoomControl: true,
    editable: true,
  };
  useEffect(() => {
    setRenderMap(false);
    const timeout = setTimeout(() => {
      setRenderMap(true);
    }, 2500);
    return () => clearTimeout(timeout);
  }, [data]);

  return (
    <>
      {isLoaded && renderMap && (
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={zoomed}
          center={centroid}
          options={options}
          onClick={handleMapClick}
        >
          {isLoaded && renderMap && children}
        </GoogleMap>
      )}
    </>
  );
};

export default StyledMap;
