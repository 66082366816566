import AppContentModel from "../models/appContentModel";

const Content: AppContentModel = {

  stations:{
    namePlaceholder : "Name",
    cityPlaceholder: "City",
    countryPlaceholder:"Country",
    nameRequiredMessage:"Name is required",
    cityRequiredMessage : "City is required",
    countryRequiredMessage : "Country is required"
  },
  cityTier:{
    descriptionPlaceholder:'Description',
    namePlaceHolder:'Name',
    nameRequiredMessage:"Name is required",
    descriptionRequiredMessage:"Description is required",

  },

  navigation: {
    stations : "Stations",
    companies: "Companies",
    customers: "Customers",
    members: "Members",
    shipments: "Shipments",
    couriers: "Couriers",
    switchLanguage: "العربية",
    arabic: "العربية",
    english: "English",
    logout: "Logout",
    draft: "Drafts",
    dashboard:"Dashboard",
    reports:"Reports",
    cityTiers:'City Tiers'
  },
  login: {
    title: "Login",
    username: "Username",
    usernamePlaceholder: "Enter Username",
    password: "Password",
    passwordPlaceholder: "Enter Password",
    forgot1: "Forgot",
    forgot2: "Password?",
    submit: "Login",
  },
  shipments: {
    completeDeliveryCycle: "Complete Delivery",
    markAsLost : "Mark As Lost",
    transferButton:"Assign for Transfer",
    referencesHeader: "References",
    customerHeader: "Customer",
    fromHeader: "Shipper",
    toHeader: "Consignee",
    statusHeader: "Status",
    courierHeader: "Courier",
    forcePickupButton: 'Force Pickup',
    codHeader: "COD",
    courierRunSheetButton: "Courier RunSheet",
    creationDateHeader: "Created On",
    commodityDetailsheader: "Commodity",
    consigneeNameSearch: "Consignee Name",
    consigneeNumberSearch: "Consignee Number",
    runsheetModal: {
      export: "Export",
      title: "Courier Run Sheet",
    },
    actions: "Actions",
    add: "Add Shipments",
    new: "New Shipment",
    import: "Import Excel",
    importWithoutStore: "Import Excel Without Store",
    export: "Export to Excel",
    search: "Search",
    clear: "Clear",
    receiveButton: "Receive",
    assignDriverButton: "Assign For Pickup",
    assignCourierButton: "Assign For Delivery",
    receiveReturnedButton: "Receive Returned",
    returnToCustomerButton: "Assign for return",
    collectCodButton: "Collect COD",
    consigneeCity: "Consignee City",
    total: "Total",
    deliveryDateHeader: "Delivery Date",
    updateShipment: {
      title: "Update Shipment",
      submit: "Save",
    },
    codCollect: {
      title: "Collect COD",
      submit: "Collect",
      change: "Change",
    },
    model: {
      stationId:"Station",
      id: "ID",
      number: "Number",
      uniqueReference: "Reference",
      status: "Status",
      customerId: "Customer",
      customerName: "Customer",
      courierId: "Courier",
      paymentMethod:"Payment Method",
      commodity: {
        numberOfPieces:"Number of pieces",
        cod: "COD",
        description: "Description",
        dimensions: "Dimensions",
        reference: "Commodity Reference",
        weight: "Weight",
      },
      consignee: {
        address: {
          country: "To Country",
          city: "To City",
          state: "To State",
          reference: "To Reference",
          postCode: "To Post Code",
          addressLine1: "To Line 1",
          addressLine2: "To Line 2",
          addressLine3: "To Line 3",
        },
        contact: {
          name: "To Name",
          email: "To Email",
          phone: "To Phone",
          secondPhone: "To Second Phone",
        },
        reference: "To Reference",
      },
      shipper: {
        address: {
          country: "From Country",
          city: "From City",
          state: "From State",
          reference: "From Reference",
          postCode: "From Post Code",
          addressLine1: "From Line 1",
          addressLine2: "From Line 2",
          addressLine3: "From Line 3",
        },
        contact: {
          name: "From Name",
          email: "From Email",
          phone: "From Phone",
          secondPhone: "From Second Phone",
        },
        reference: "From Reference",
      },
      shippingMethod: "Shipping Method",
      parcelatUser: "Parcelat User",
    },
    shipmentTrackModel: {
      category: 'Category',
      deliveryService: 'Service Type',
      stationId:"Station",
      title: "Shipment Track",
      uniqueReferenceLabel: "Unique Reference",
      customerLabel: "Customer",
      shipmentNumberLabel: "Number",
      trace: {
        index: "#",
        activity: "Activity",
        by: "By",
        additionalData: "Additional Data",
        comment: "Comment",
        date: "Date",
      },
      consigneeTitle: "Consignee",
      shipperTitle: "Shipper",
      commodityTitle: "Commodity",
      commodity: {
        numberOfPieces: "Number of pieces",
        cod: "COD",
        description: "Description",
        dimensions: "Dimensions",
        weight: "Weight",
      },
      address: {
        country: "Country",
        city: "City",
        postCode: "Post Code",
        state: "State",
        addressLine1: "Address Line 1",
        addressLine2: "Address Line 2",
        addressLine3: "Address Line 3",
      },
      contact: {
        email: "Email",
        name: "Name",
        phone: "Phone",
        secondPhone: "Second Phone",
      },
    },
    newShipment: {
      title: "New Shipment",
      fromStore: "From Store",
      toStore: "To Store",
      numberOfPiecesField: { placeHolder: "Number of pieces",requiredMessage: "Number of pieces is required" , minimum:"Minimum(1)"},
      customerField: {
        placeHolder: "Customer",
        requiredMessage: "",
      },
      customerStoreField: {
        placeHolder: "Store",
        requiredMessage: "",
      },
      commodityDescriptionField: {
        placeHolder: "Description",
        requiredMessage: "",
      },
      uniqueReferenceField: {
        placeHolder: "Unique Reference",
        requiredMessage: "Unique reference is required",
      },
      cityField: {
        placeHolder: "City",
        requiredMessage: "City is required",
      },
      addressLine1Field: {
        placeHolder: "Address Line 1",
        requiredMessage: "Address Line 1 is required",
      },
      addressLine2Field: {
        placeHolder: "Address Line 2",
        requiredMessage: "Address Line 2 is required",
      },
      addressLine3Field: {
        placeHolder: "Address Line 3",
        requiredMessage: "Address Line 3 is required",
      },
      codField: {
        placeHolder: "COD",
        requiredMessage: "",
      },
      weightField: {
        placeHolder: "Weight",
        requiredMessage: "Weight is required",
      },
      dimensionsField: {
        placeHolder: "Dimensions",
        requiredMessage: "Please complete this field",
      },
      nameField: {
        placeHolder: "Name",
        requiredMessage: " Name must not be empty",
      },
      emailField: {
        placeHolder: "Email",
        requiredMessage: "Email must not be empty",
      },
      phoneField: {
        placeHolder: "Phone",
        requiredMessage: "Phone Must not be empty",
      },
      secondPhoneField: {
        placeHolder: "Second Phone",
        requiredMessage: "",
      },
      commoditySectionTitle: "Commodity",
      shipperSectionTitle: "Shipper",
      consigneeSectionTitle: "Consignee",
      submit: "Submit",
      itemName: "Item Name",
      consigneeAdressTitle: "Consignee Address",
    },
    receive: {
      title: "Receive Shipments",
      scanPlaceholder: "Scan Shipment",
      addButton: "Add",
      clearAllButton: "Clear All",
      clearButton: "Clear",
      receiveButton: "Receive",
    },
    selectCourier: {
      title: "Assign A Driver",
      button: "Assign",
      placeholder: "Select a courier",
    },

    returnToCustomer: {
      title: "Return to customer",
      button: "Assign",
      placeholder: "Select a driver",
    },
  },
  members: {
    Station:"Station",
    assignStation: "Assign Station",
    changeRole: "Change Role",
    nameContainsPlaceholder: "Name",
    mobileContainsPlaceholder: "Mobile",
    searchButtonPlaceholder: "Search",
    actionsHeader: "Actions",
    emailHeader: "Email",
    mobileHeader: "Mobile",
    nameHeader: "Name",
    newButton: "Add Member",
    update: "Update",
    jobRoleHeader: "Role",
    newMember: {
      title: "Add new Member",
      submit: "Save",
      email: {
        placeHolder: "Email",
        requiredMessage: "Email is required",
      },
      mobile: {
        placeHolder: "Mobile",
        requiredMessage: "Mobile is required",
      },

      name: {
        placeHolder: "Name",
        requiredMessage: "Name is required",
      },
      password: {
        placeHolder: "Password",
        requiredMessage: "Password is required",
      },
      jobRole: {
        placeHolder: "Role",
        requiredMessage: "Role is required",
      },
    },
  },
  customers: {
    addStoreContent: {
      storeName: {
        placeHolder: "Store name",
        requiredMessage: "Store name Must not be empty",
      },


      passwordField: {
        placeHolder: "Password",
        requiredMessage: "Password is required",
      },
      secondePhoneField: {
        placeHolder: "Second Phone",
        requiredMessage: "Second Phone Must not be empty",
      },
      phoneField: {
        placeHolder: "Phone",
        requiredMessage: "Phone Must not be empty",
      },
      storeSectionTitle: "Add store",

      titleField: {
        placeHolder: "Title",
        requiredMessage: "Title Must not be empty",
      },

      submit: "Submit",
      storeEmailField: {
        placeHolder: "Email",
        requiredMessage: "Email is a required field",
      },

      cityField: {
        placeHolder: "City",
        requiredMessage: "City is required",
      },

      addressLine1Field: {
        placeHolder: "Address Line 1",
        requiredMessage: "Address Line 1 is required",
      },
      addressLine2Field: {
        placeHolder: "Address Line 2",
        requiredMessage: "Address Line 2 is required",
      },

      addressLine3Field: {
        placeHolder: "Address Line 3",
        requiredMessage: "Address Line 3 is required",
      },
    },
    actions: "Actions",
    add: "Add Customer",
    new: "New Customer",
    export: "Export to Excel",
    nameContains: "Name",
    emailContains: "Email",
    searchButtonPlaceholder: "Search",
    clear: "Clear",
    nameHeader: "Name",
    newCustomer: {
      title: "Add a new Customer",
      submit: "Submit",
      passwordSection: "Customer Credentials",
      password: {
        placeHolder: "Password",
        requiredMessage: "Password is required",
      },

      name: {
        placeHolder: "Arabic title",
        requiredMessage: "Arabic title is a required field",
      },
      email: {
        placeHolder: "Email",
        requiredMessage: "Email is a required field",
      },
    },
    editStore:{
      title: "Edit Store",
      name: "Store name",
      submit: "Submit"
    },
  },
  reports:{

  },
  cities:{
    idPlaceholder:"ID",
    namePlaceholder:"Name",
    stationPlaceholder:"Station",
    isServicedPlaceholder:"Is Serviced",
    tierName:'Tier name',
    tierUpdate:'Update'
  }
};

export default Content;
