import React, { useMemo } from "react";
import { MarkerF } from "@react-google-maps/api";
import { faLocationPin } from "@fortawesome/free-solid-svg-icons";
import StyledMap from "./styledMap";
import { IMachinesMappedData } from "../../models/parcelat";

type IMap = {
  values?: any;
  setLockerInfo: React.Dispatch<React.SetStateAction<IMachinesMappedData>>;
  machines: IMachinesMappedData[];
  mapHeight?: string;
};

const AssignLockerOnMap = ({ values, setLockerInfo, machines, mapHeight }: IMap) => {
  const cordinates = useMemo(() => {
    const lngLat = {
      lat: 24.7136, // Riyadh latitude
      lng: 46.6753, // Riyadh longitude
    };
    return lngLat;
  }, [values?.latitude, values?.longitude]);

  return (
    <StyledMap
      zoomed={12}
      centroid={cordinates}
      mapContainerHeight={mapHeight}
      children={
        <>
          {machines?.map((point, index) => (
            <MarkerF
              onClick={() => setLockerInfo(point)}
              key={index}
              position={{ lat: point.lat ?? 0, lng: point.lng ?? 0 }}
              title={""}
              icon={{
                path: faLocationPin.icon[4] as string,
                fillColor: "blue",
                fillOpacity: 1,
                strokeWeight: 1,
                strokeColor: "#ffffff",
                scale: 0.06,
              }}
            />
          ))}
        </>
      }
    />
  );
};

export default AssignLockerOnMap;
