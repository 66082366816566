import React, { useEffect } from "react";
import Modal from "react-bootstrap/esm/Modal";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import { GetCustomer, SearchLockersSizes } from "../../../api/data";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import AppStateModel from "../../../models/appStateModel";
import { useDispatch, useSelector } from "react-redux";
import ICreateShipmentModel from "../../../models/createShipmentModel";
import { CreateShipmentAsync } from "../../../actions/shipmentsActions";
import TextInput from "../../components/TextInput";
import SelectInput from "../../components/SelectInput";
import CitiesSelect from "../../components/CitiesSelect";
import { PartyModel } from "../../../models/common/commonModels";
import CustomerStoreModel from "../../../models/customers/customerStoreModel";

interface IProps {
  visible: boolean;
  onHide: () => any;
}

const Component = (props: IProps & any) => {
  const content = useSelector(
    (state: AppStateModel) => state.AppContent.shipments.newShipment
  );
  const meta = useSelector((state: AppStateModel) => state.Meta);
  const user = useSelector((state: AppStateModel) => state.Profile);
  const [newShipment, setNewShipment] = React.useState<ICreateShipmentModel>({
    commodity: { numberOfPieces: "1" },
  });

  const [toStore, setToStore] = React.useState(false);
  const [fromStore, setFromStore] = React.useState(false);
  const [selectedCustomerStores, setSelectedCustomerStores] = React.useState<
    any[]
  >([]);
  const [selectedCustomerStoresOptions, setSelectedCustomerStoresOptions] =
    React.useState([]);

  const [validationErrors, setValidationErrors] = React.useState<string[]>([]);
  const [lockerSizes, setLockerSizes] = React.useState<any[]>([]);
  const setCustomerId = () => {
    setNewShipment({ ...newShipment, customerId: user.customerId });
    getStoresOptions(user.customerId);
  };
  React.useEffect(() => {
    if (user.jobRole == "CustomerAdmin") {
      setCustomerId();
    }
    // setNewShipment({
    //   ...newShipment,
    //   commodity: {
    //     ...newShipment.commodity!,
    //     numberOfPieces: 1,
    //   },
    // });
  }, []);

  const dispatch = useDispatch();

  const onSubmit = (e: any) => {
    e.preventDefault();
    let validations: string[] = [];

    !newShipment.commodity?.dimensions &&
      validations.push("dimensions");
    !newShipment.customerId &&
      newShipment.customerId == null &&
      validations.push("CustomerId");
    (!newShipment.uniqueReference || false) &&
      validations.push("uniqueReference");
    (!newShipment.shipper ||
      !newShipment.shipper?.contact ||
      !newShipment.shipper?.contact.name ||
      false) &&
      validations.push("shipperName");
    (!newShipment.shipper ||
      !newShipment.shipper?.contact ||
      !newShipment.shipper?.contact.phone ||
      false) &&
      validations.push("shipperPhone");
    (!newShipment.shipper ||
      !newShipment.shipper?.address ||
      !newShipment.shipper?.address.city ||
      false) &&
      validations.push("shipperCity");
    (!newShipment.shipper ||
      !newShipment.shipper?.address ||
      !newShipment.shipper?.address.addressLine1 ||
      false) &&
      validations.push("shipperLine1");
    // (!newShipment.shipper ||
    // 	!newShipment.shipper?.address ||
    // 	!newShipment.shipper?.address.addressLine2 ||
    // 	newShipment.shipper?.address.addressLine2 == null) &&
    // 	validations.push("shipperLine2");

    (!newShipment.consignee ||
      !newShipment.consignee?.contact ||
      !newShipment.consignee?.contact.name ||
      false) &&
      validations.push("consigneeName");
    (!newShipment.consignee ||
      !newShipment.consignee?.contact ||
      !newShipment.consignee?.contact.phone ||
      false) &&
      validations.push("consigneePhone");
    // (!newShipment.consignee ||
    //   !newShipment.consignee?.address ||
    //   !newShipment.consignee?.address.city ||
    //   false) &&
    //   validations.push("consigneeCity");
    (!newShipment.consignee ||
      !newShipment.consignee?.address ||
      !newShipment.consignee?.address.addressLine1 ||
      false) &&
      validations.push("consigneeLine1");
    // (!newShipment.consignee ||
    // 	!newShipment.consignee?.address ||
    // 	!newShipment.consignee?.address.addressLine2 ||
    // 	newShipment.consignee?.address.addressLine2 == null) &&
    // 	validations.push("consigneeLine2");
    //
    (!newShipment.commodity || !newShipment.commodity?.weight || false) &&
      validations.push("weight");

    (!newShipment.commodity ||
      !newShipment.commodity?.numberOfPieces ||
      false ||
      newShipment.commodity?.numberOfPieces.startsWith("0")) &&
      validations.push("numberOfPieces");

    if (validations.length > 0) {
      setValidationErrors(validations);
    } else {
      dispatch(CreateShipmentAsync(newShipment, () => props.onHide()));
    }
  };
  
  const getStoresOptions = async (selectedCustomerId: any) => {
    if (selectedCustomerId) {
      let options: any = [];
      let res = await GetCustomer(selectedCustomerId);
      if (res.succeeded && res.data) {
        setSelectedCustomerStores(res.data.stores);
        res.data.stores?.forEach((store: any) => {
          options.push({ value: store.id, label: store.name });
        });
      }
      setSelectedCustomerStoresOptions(options);
    }
  };

  const getLockerSizes = async () => {
    let res = await SearchLockersSizes();
    if (res.succeeded && res.data) {
      setLockerSizes(res.data);
    }
  };

  useEffect(() => {
    getLockerSizes();
  }, []);

  const selectStoreFrom = (val: any) => {
    if (val) {
      let store: CustomerStoreModel = selectedCustomerStores.find(
        (s) => s.id == val
      );
      setNewShipment({
        ...newShipment,
        shipper: {
          reference: val,
          address: {
            addressLine1: store.addressLine1,
            addressLine2: store.addressLine2,
            addressLine3: store.addressLine3,
            postCode: store.postCode,
            city: store.city,
          },
          contact: {
            email: store.email,
            name: store.name,
            phone: store.phone,
            secondPhone: store.secondPhone,
          },
        },
      });
      setFromStore(true);
    } else {
      setNewShipment({ ...newShipment, shipper: emptyParty() });
      setFromStore(false);
    }
  };

  const selectStoreTo = (val: any) => {
    if (val) {
      let store = selectedCustomerStores.find((s) => s.id == val);
      setNewShipment({
        ...newShipment,
        consignee: {
          reference: val,
          address: {
            addressLine1: store.addressLine1,
            addressLine2: store.addressLine2,
            addressLine3: store.addressLine3,
            postCode: store.postCode,
            city: store.city,
          },
          contact: {
            email: store.email,
            name: store.name,
            phone: store.phone,
            secondPhone: store.secondPhone,
          },
        },
      });
      setToStore(true);
    } else {
      setNewShipment({
        ...newShipment,
        consignee: emptyParty(),
      });
      setToStore(false);
    }
  };

  console.log({ validationErrors });

  return (
    <>
      <Modal
        show={props.visible}
        onHide={() => props.onHide()}
        dialogClassName="modal-100w"
        aria-labelledby="example-custom-modal-styling-title"
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            {content.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={onSubmit}>
            <Form.Row>
              <Col>
                <SelectInput
                  disabled={user.jobRole == "CustomerAdmin"}
                  value={newShipment.customerId}
                  onChange={(id) => {
                    setNewShipment({ ...newShipment, customerId: id });
                    getStoresOptions(id);
                  }}
                  options={meta?.customers!}
                  placeholder={content.customerField.placeHolder + "*"}
                />
              </Col>

              <Col>
                <TextInput
                  value={newShipment.uniqueReference!}
                  onChange={(val) =>
                    setNewShipment({ ...newShipment, uniqueReference: val })
                  }
                  placeholder={content.uniqueReferenceField.placeHolder}
                  required={true}
                  invalidMessage={content.uniqueReferenceField.requiredMessage}
                  isInvalid={
                    !!validationErrors.find((e) => e == "uniqueReference")
                  }
                />
              </Col>
            </Form.Row>
            <Form.Row>
              <Col>
                <Card>
                  <Card.Body>
                    <Card.Title>{content.shipperSectionTitle}</Card.Title>

                    <SelectInput
                      value={newShipment.shipper?.reference}
                      onChange={(id) => selectStoreFrom(id)}
                      options={selectedCustomerStoresOptions}
                      placeholder={content.fromStore}
                      //	placeholder="hi"
                      disabled={
                        newShipment.customerId == undefined ||
                        newShipment.customerId == null
                      }
                    />

                    <TextInput
                      value={newShipment.shipper?.contact?.name!}
                      onChange={(val) =>
                        setNewShipment({
                          ...newShipment,
                          shipper: {
                            ...newShipment.shipper!,
                            contact: {
                              ...newShipment.shipper?.contact!,
                              name: val,
                            },
                          },
                        })
                      }
                      placeholder={content.nameField.placeHolder}
                      required={true}
                      disabled={fromStore}
                      invalidMessage={content.nameField.requiredMessage}
                      isInvalid={
                        !!validationErrors.find((e) => e == "shipperName")
                      }
                    />
                    <TextInput
                      value={newShipment.shipper?.contact?.email!}
                      onChange={(val) =>
                        setNewShipment({
                          ...newShipment,
                          shipper: {
                            ...newShipment.shipper!,
                            contact: {
                              ...newShipment.shipper?.contact!,
                              email: val,
                            },
                          },
                        })
                      }
                      placeholder={content.emailField.placeHolder}
                      disabled={fromStore}
                    />
                    <TextInput
                      value={newShipment.shipper?.contact?.phone!}
                      onChange={(val) =>
                        setNewShipment({
                          ...newShipment,
                          shipper: {
                            ...newShipment.shipper!,
                            contact: {
                              ...newShipment.shipper?.contact!,
                              phone: val,
                            },
                          },
                        })
                      }
                      placeholder={content.phoneField.placeHolder}
                      required={true}
                      disabled={fromStore}
                      invalidMessage={content.phoneField.requiredMessage}
                      isInvalid={
                        !!validationErrors.find((e) => e == "shipperPhone")
                      }
                    />
                    <TextInput
                      value={newShipment.shipper?.contact?.secondPhone!}
                      onChange={(val) =>
                        setNewShipment({
                          ...newShipment,
                          shipper: {
                            ...newShipment.shipper!,
                            contact: {
                              ...newShipment.shipper?.contact!,
                              secondPhone: val,
                            },
                          },
                        })
                      }
                      placeholder={content.secondPhoneField.placeHolder}
                      disabled={fromStore}
                      invalidMessage={content.secondPhoneField.requiredMessage}
                    />
                    <CitiesSelect
                      value={newShipment.shipper?.address?.city!}
                      onChange={(val) =>
                        setNewShipment({
                          ...newShipment,
                          shipper: {
                            ...newShipment.shipper!,
                            address: {
                              ...newShipment.shipper?.address!,
                              city: val,
                            },
                          },
                        })
                      }
                      placeholder={content.cityField.placeHolder}
                      // required={true}
                      disabled={fromStore}
                      invalidMessage={content.cityField.requiredMessage}
                      isInvalid={
                        !!validationErrors.find((e) => e == "shipperCity")
                      }
                    />

                    <TextInput
                      value={newShipment.shipper?.address?.addressLine1!}
                      onChange={(val) =>
                        setNewShipment({
                          ...newShipment,
                          shipper: {
                            ...newShipment.shipper!,
                            address: {
                              ...newShipment.shipper?.address!,
                              addressLine1: val,
                            },
                          },
                        })
                      }
                      placeholder={content.addressLine1Field.placeHolder}
                      required={true}
                      disabled={fromStore}
                      invalidMessage={content.addressLine1Field.requiredMessage}
                      isInvalid={
                        !!validationErrors.find((e) => e == "shipperLine1")
                      }
                    />
                    <TextInput
                      value={newShipment.shipper?.address?.addressLine2!}
                      onChange={(val) =>
                        setNewShipment({
                          ...newShipment,
                          shipper: {
                            ...newShipment.shipper!,
                            address: {
                              ...newShipment.shipper?.address!,
                              addressLine2: val,
                            },
                          },
                        })
                      }
                      placeholder={content.addressLine2Field.placeHolder}
                      required={true}
                      disabled={fromStore}
                      invalidMessage={content.addressLine2Field.requiredMessage}
                      isInvalid={
                        !!validationErrors.find((e) => e == "shipperLine2")
                      }
                    />
                    <TextInput
                      value={newShipment.shipper?.address?.addressLine3!}
                      onChange={(val) =>
                        setNewShipment({
                          ...newShipment,
                          shipper: {
                            ...newShipment.shipper!,
                            address: {
                              ...newShipment.shipper?.address!,
                              addressLine3: val,
                            },
                          },
                        })
                      }
                      disabled={fromStore}
                      placeholder={content.addressLine3Field.placeHolder}
                      invalidMessage={content.addressLine3Field.requiredMessage}
                    />
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Body>
                    <Card.Title>{content.consigneeSectionTitle}</Card.Title>
                    <Form.Group>
                      <SelectInput
                        value={newShipment.consignee?.reference}
                        onChange={(id) => selectStoreTo(id)}
                        options={selectedCustomerStoresOptions}
                        placeholder={content.toStore}
                        disabled={
                          newShipment.customerId == undefined ||
                          newShipment.customerId == null
                        }
                      />
                    </Form.Group>
                    <TextInput
                      value={newShipment.consignee?.contact?.name!}
                      onChange={(val) =>
                        setNewShipment({
                          ...newShipment,
                          consignee: {
                            ...newShipment.consignee!,
                            contact: {
                              ...newShipment.consignee?.contact!,
                              name: val,
                            },
                          },
                        })
                      }
                      placeholder={content.nameField.placeHolder}
                      required={true}
                      disabled={toStore}
                      invalidMessage={content.nameField.requiredMessage}
                      isInvalid={
                        !!validationErrors.find((e) => e == "consigneeName")
                      }
                    />
                    <TextInput
                      value={newShipment.consignee?.contact?.email!}
                      onChange={(val) =>
                        setNewShipment({
                          ...newShipment,
                          consignee: {
                            ...newShipment.consignee!,
                            contact: {
                              ...newShipment.consignee?.contact!,
                              email: val,
                            },
                          },
                        })
                      }
                      placeholder={content.emailField.placeHolder}
                      disabled={toStore}
                      invalidMessage={content.emailField.requiredMessage}
                    />
                    <TextInput
                      value={newShipment.consignee?.contact?.phone!}
                      onChange={(val) =>
                        setNewShipment({
                          ...newShipment,
                          consignee: {
                            ...newShipment.consignee!,
                            contact: {
                              ...newShipment.consignee?.contact!,
                              phone: val,
                            },
                          },
                        })
                      }
                      placeholder={content.phoneField.placeHolder}
                      required={true}
                      disabled={toStore}
                      invalidMessage={content.phoneField.requiredMessage}
                      isInvalid={
                        !!validationErrors.find((e) => e == "consigneePhone")
                      }
                    />
                    <TextInput
                      value={newShipment.consignee?.contact?.secondPhone!}
                      onChange={(val) =>
                        setNewShipment({
                          ...newShipment,
                          consignee: {
                            ...newShipment.consignee!,
                            contact: {
                              ...newShipment.consignee?.contact!,
                              secondPhone: val,
                            },
                          },
                        })
                      }
                      placeholder={content.secondPhoneField.placeHolder}
                      disabled={toStore}
                      invalidMessage={content.secondPhoneField.requiredMessage}
                    />
                    <CitiesSelect
                      value={newShipment.consignee?.address?.city!}
                      onChange={(val) =>
                        setNewShipment({
                          ...newShipment,
                          consignee: {
                            ...newShipment.consignee!,
                            address: {
                              ...newShipment.consignee?.address!,
                              city: val,
                            },
                          },
                        })
                      }
                      placeholder={content.cityField.placeHolder}
                      // required={true}
                      disabled={toStore}
                      invalidMessage={content.cityField.requiredMessage}
                      isInvalid={
                        !!validationErrors.find((e) => e == "consigneeCity")
                      }
                    />
                    {/* <TextInput
										value={newShipment.consignee?.address?.city!}
										onChange={(val) =>
											setNewShipment({
												...newShipment,
												consignee: {
													...newShipment.consignee!,
													address: {
														...newShipment.consignee?.address!,
														city: val,
													},
												},
											})
										}
										isInvalid={
											validationErrors.find((e) => e == "consigneeCity")
												? true
												: false
										}
										placeholder={content.cityField.placeHolder}
										required={true}
										disabled={toStore}
										invalidMessage={content.cityField.requiredMessage}
									/> */}
                    <TextInput
                      value={newShipment.consignee?.address?.addressLine1!}
                      onChange={(val) =>
                        setNewShipment({
                          ...newShipment,
                          consignee: {
                            ...newShipment.consignee!,
                            address: {
                              ...newShipment.consignee?.address!,
                              addressLine1: val,
                            },
                          },
                        })
                      }
                      placeholder={content.addressLine1Field.placeHolder}
                      disabled={toStore}
                      required={true}
                      invalidMessage={content.addressLine1Field.requiredMessage}
                      isInvalid={
                        !!validationErrors.find((e) => e == "consigneeLine1")
                      }
                    />
                    <TextInput
                      value={newShipment.consignee?.address?.addressLine2!}
                      onChange={(val) =>
                        setNewShipment({
                          ...newShipment,
                          consignee: {
                            ...newShipment.consignee!,
                            address: {
                              ...newShipment.consignee?.address!,
                              addressLine2: val,
                            },
                          },
                        })
                      }
                      placeholder={content.addressLine2Field.placeHolder}
                      disabled={toStore}
                      required={true}
                      invalidMessage={content.addressLine2Field.requiredMessage}
                      isInvalid={
                        !!validationErrors.find((e) => e == "consigneeLine2")
                      }
                    />
                    <TextInput
                      value={newShipment.consignee?.address?.addressLine3!}
                      onChange={(val) =>
                        setNewShipment({
                          ...newShipment,
                          consignee: {
                            ...newShipment.consignee!,
                            address: {
                              ...newShipment.consignee?.address!,
                              addressLine3: val,
                            },
                          },
                        })
                      }
                      disabled={toStore}
                      placeholder={content.addressLine3Field.placeHolder}
                      invalidMessage={content.addressLine3Field.requiredMessage}
                    />
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Body>
                    <Card.Title>{content.commoditySectionTitle}</Card.Title>

                    <TextInput
                      value={newShipment.commodity?.weight!}
                      onChange={(val) =>
                        setNewShipment({
                          ...newShipment,
                          commodity: {
                            ...newShipment.commodity!,
                            weight: val,
                          },
                        })
                      }
                      placeholder={content.weightField.placeHolder}
                      required={true}
                      invalidMessage={content.weightField.requiredMessage}
                      isInvalid={!!validationErrors.find((e) => e == "weight")}
                    />

                    <SelectInput
                      value={newShipment.commodity?.dimensions!}
                      onChange={(val) =>
                        setNewShipment({
                          ...newShipment,
                          commodity: {
                            ...newShipment.commodity!,
                            dimensions: val?.toString(),
                          },
                        })
                      }
                      options={lockerSizes}
                      placeholder={content.dimensionsField.placeHolder}
                      isInvalid={
                        !!validationErrors.find((e) => e == "dimensions")
                      }
                      invalidMessage={content.dimensionsField.requiredMessage}
                    />

                    <TextInput
                      value={newShipment.commodity?.cod!}
                      onChange={(val) =>
                        setNewShipment({
                          ...newShipment,
                          commodity: {
                            ...newShipment.commodity!,
                            cod: val,
                          },
                        })
                      }
                      placeholder={content.codField.placeHolder}
                    />
                    <TextInput
                      value={newShipment.commodity?.description!}
                      onChange={(val) =>
                        setNewShipment({
                          ...newShipment,
                          commodity: {
                            ...newShipment.commodity!,
                            description: val,
                          },
                        })
                      }
                      placeholder={
                        content.commodityDescriptionField.placeHolder
                      }
                    />
                    <Form.Label>
                      {content.numberOfPiecesField.placeHolder}
                    </Form.Label>
                    <TextInput
                      placeholder={content.numberOfPiecesField.minimum}
                      value={newShipment.commodity?.numberOfPieces!}
                      invalidMessage={
                        content.numberOfPiecesField.requiredMessage
                      }
                      isInvalid={
                        !!validationErrors.find((e) => e == "numberOfPieces")
                      }
                      onChange={(val) =>
                        setNewShipment({
                          ...newShipment,
                          commodity: {
                            ...newShipment.commodity!,
                            numberOfPieces: val,
                          },
                        })
                      }
                    />
                    <TextInput
                      placeholder={content.itemName}
                      value={newShipment.commodity?.itemName}
                      onChange={(val) =>
                        setNewShipment({
                          ...newShipment,
                          commodity: {
                            ...newShipment.commodity!,
                            itemName: val,
                          },
                        })
                      }
                    />
                    <SelectInput
                      // disabled={user.jobRole == "CustomerAdmin"}
                      value={newShipment?.deliveryService}
                      onChange={(id) => {
                        setNewShipment({
                          ...newShipment,
                          deliveryService: Number(id),
                        });
                      }}
                      options={meta?.deliveryServices!}
                      placeholder={"Delivery Service"}
                    />

                    <SelectInput
                      // disabled={user.jobRole == "CustomerAdmin"}
                      value={newShipment?.category}
                      onChange={(id) => {
                        setNewShipment({
                          ...newShipment,
                          category: Number(id),
                        });
                      }}
                      options={meta?.categories!}
                      placeholder={"Category"}
                    />
                  </Card.Body>
                </Card>
                <div
                  style={{
                    paddingTop: 80,
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <Button
                    size="lg"
                    onClick={onSubmit}
                    type="submit"
                    variant="light"
                  >
                    {content.submit}
                  </Button>
                </div>
              </Col>
            </Form.Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

const emptyParty = (): PartyModel => {
  return {
    reference: undefined,
    address: {
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
    },
    contact: {
      email: "",
      name: "",
      phone: "",
      secondPhone: "",
    },
  };
};

export default Component;
