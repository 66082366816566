import React, { useState, useEffect } from "react";
import Image from "react-bootstrap/Image";
import ChooseLockerModal from "./chooseLockerModal";
import DeliverToHomeModal from "./deliverToHome";
import { CheckIfUserHasChoosen } from "../../api/data";
import { useLocation } from "react-router-dom";

const ChooseShippingMethod = () => {
  const [modal, setModal] = useState<"LOCKER" | "DELIVER" | "NONE">("NONE");
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
  const [choosed, setChoosed] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 660);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const CheckIfMethodChoosen = async () => {
    const params = new URLSearchParams(location.search);
    const shipmentId = params.get("shipmentId") ?? "";
    const response = await CheckIfUserHasChoosen({ shipmentId });
    setChoosed(response.data);
  };

  useEffect(() => {
    CheckIfMethodChoosen();
  }, []);

  const handleCardClick = (method: "LOCKER" | "DELIVER") => {
    setModal(method);
  };

  const handleCloseModal = ( ) => {
    setModal("NONE");
    CheckIfMethodChoosen();
  }

  return (
    <div style={styles.container}>
      <div style={styles.content}>
        {!choosed ? (
          <>
            <div style={styles.headerText}>Your shipment is on the way</div>
            <div style={styles.subHeaderText}>
              Where do you want it to be delivered
            </div>
          </>
        ) : (
          <div style={styles.headerText}>Thanks for choosing !</div>
        )}
        <Image
          src="/parcelatHeroImage.png"
          style={isMobile ? styles.mobileImage : styles.image}
          roundedCircle
        />
        {!choosed && (
          <div
            style={isMobile ? styles.mobileCardContainer : styles.cardContainer}
          >
            <DeliveryCard
              label="Deliver To My Home"
              onClick={() => handleCardClick("DELIVER")}
            />
            <DeliveryCard
              label="Deliver To A Locker"
              onClick={() => handleCardClick("LOCKER")}
            />
          </div>
        )}
      </div>
      {modal === "LOCKER" && (
        <ChooseLockerModal
          isOpen={modal === "LOCKER"}
          onClose={handleCloseModal}
        />
      )}
      {modal === "DELIVER" && (
        <DeliverToHomeModal
          isOpen={modal === "DELIVER"}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

type DeliveryCardProps = {
  label: string;
  onClick: () => void;
};

const DeliveryCard: React.FC<DeliveryCardProps> = ({ label, onClick }) => (
  <div style={styles.card} onClick={onClick}>
    {label}
  </div>
);

export default ChooseShippingMethod;

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    width: "100%",
    height: "100%",
  },
  content: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#060F5E",
    flexDirection: "column",
  },
  headerText: {
    color: "white",
    fontSize: "48px",
    fontWeight: 600,
    textAlign: "center",
  },
  subHeaderText: {
    color: "white",
    fontSize: "30px",
    fontWeight: 400,
    textAlign: "center",
  },
  image: {
    width: "456px",
    height: "456px",
    marginLeft: 10,
  },
  mobileImage: {
    width: "300px",
    height: "300px",
    marginLeft: 10,
  },
  cardContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "600px",
    gap: "20px",
  },
  mobileCardContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    gap: "20px",
  },
  card: {
    display: "flex",
    borderRadius: "10px",
    cursor: "pointer",
    textAlign: "center",
    width: "270px",
    height: "80px",
    color: "#060F5E",
    backgroundColor: "#FAFAFA",
    fontSize: "24px",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 400,
  },
};
