import { SearchParcelatMachines } from "../api/data";
import { IParcelatMahinesResponse } from "../models/parcelat";
import { SET_PARCELAT_MACHINES } from "../store/types";
import { SetAlert, setLoading } from "./common";

export const SearchParcelatMachinesAsync = () => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    const res = await SearchParcelatMachines();
    if (res.succeeded) {
      dispatch(searchMachines(res.data));
    } else {
      dispatch(
        SetAlert({
          visible: true,
          data: res.data,
          title: "Error",
          kind: "error",
        })
      );
    }
    dispatch(setLoading(false));
  };
};

export const searchMachines = (machines: IParcelatMahinesResponse) => {
  return {
    type: SET_PARCELAT_MACHINES,
    payload: {
      machines,
    },
  };
};
