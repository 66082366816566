interface Address {
  country: string;
  city: string;
  address1: string;
  address2: string;
  zipCode: string;
  street: string;
  buildingNumber: string;
  additionalNumber: string;
  regionName: string;
  latitude: number;
  longitude: number;
  id: number;
}

interface Machine {
  name: string;
  numberOfBoards: number;
  machineStatus: number;
  isDeleted: boolean;
  ipAddress: string;
  portNumber: string;
  displayName: string;
  stationNo: string | null;
  comments: string;
  addressId: number | null;
  vendorId: number | null;
  tenantId: number | null;
  arabicDisplayName: string | null;
  temperature: number | null;
  id: number;
}

interface Item {
  machine: Machine;
  addressCity: string;
  vendorName: string;
  tenantName: string;
  boxDetails: any; // Adjust the type of boxDetails if needed
  address: Address | null;
  machinePublishStatus: number;
}

interface Result {
  totalCount: number;
  items: Item[];
}

export interface IParcelatMahinesResponse {
  machines: {
    result?: Result;
    targetUrl: string | null;
    success: boolean;
    error: string | null;
    unAuthorizedRequest: boolean;
    __abp: boolean;
  };
}

export type IMachinesMappedData = {
  name?: string;
  displayName?: string;
  address1?: string;
  street?: string;
  lat?: number;
  lng?: number;
  googleUrl?: string;
};

export const machinesMapper = (data?: Result): IMachinesMappedData[] => {
  return (data?.items || []).filter(i => i.machine.name == "13579").map((item) => ({
    name: item.machine.name,
    displayName: item.machine.displayName,
    address1: item.address?.address1 || "",
    street: item.address?.street || "",
    lat: item.address?.latitude || 0,
    lng: item.address?.longitude || 0,
    city: item.address?.city || "",
    googleUrl: item.address?.address2 || "",
  }));
};

export interface IReserveLockerRequest {
  machineName?: string;
  shipmentId?: string;
}