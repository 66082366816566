import AppStateModel from "../models/appStateModel";

import { SetAppContent } from "../i18n";

const InitialAppState: AppStateModel = {
  AMS: {
    Cities: [],
  },
  Routes: {
    total: 0,
    matches: [],
  },
  Journeys: {
    total: 0,
    matches: [],
  },
  Loading: {
    isLoading: false,
  },
  Auth: {
    isAuthenticated: false,
  },
  Profile: {
    isComplete: false,
  },
  Locale: {
    culture: "en",
    isRtl: false,
  },
  AppContent: SetAppContent(),
  PagedShipments: {
    total: 0,
    matches: [],
  },
  PagedDraftBatches: {
    total: 0,
    matches: [],
  },

  DraftBatchesCriteria: {
    pageNumber: 1,
    pageSize: 20,
  },

  PagedStations: {
    total: 0,
    matches: [],
  },

  StationsCriteria: {
    pageNumber: 1,
    pageSize: 20,
  },

  SearchShipmentsCriteria: {
    pageNumber: 1,
    pageSize: 20,
  },
  Meta: {
    couriers: [],
    shipmentStatuses: [],
  },
  Alert: {
    visible: false,
  },

  SearchMembersCriteria: {
    pageNumber: 1,
    pageSize: 20,
  },
  SearchCustomersCriteria: {
    pageNumber: 1,
    pageSize: 20,
  },
  SearchCitiesCriteria: {
    pageNumber: 1,
    pageSize: 20,
  },
  SearchAmsCriteria: {
    pageNumber: 1,
    pageSize: 20,
  },
  SearchContainersCriteria: {
    pageNumber: 1,
    pageSize: 20,
  },
  PagedContainers: {
    total: 0,
    matches: [],
  },
  CityTiers: {
    total: 0,
    matches: [],
  },
  SearchOtpCriteria: {
    pageNumber: 1,
    pageSize: 20,
    shipmenNumber: "",
  },
  SearchPartnerCriteria: {
    pageNumber: 1,
    pageSize: 20,
    email: "",
    name: "",
  },
  PagedOtp: {
    total: 0,
    matches: [],
  },
  PagedPartner: {
    total: 0,
    matches: [],
  },
  SearchNotifications: {
    total: 0,
    matches: [],
  },
  NotificationsRequest: {
    pageNumber: 1,
    pageSize: 20,
  },
  SearchCustomerNotifications: {
    total: 0,
    totalUnread: 0,
    matches: [],
  },
  ParcelatMachines: {
    machines: {
      success: false,
      __abp: false,
      error: "",
      result: undefined,
      targetUrl: "",
      unAuthorizedRequest: false,
    },
  },
};

export default InitialAppState;
