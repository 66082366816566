import {ApiResponse, ClientFactory, GetClientConfig,} from "@simplify9/simplyapiclient";
import SearchMembersCriteria from "../models/members/searchMembersCriteria";
import SearchShipmentsCriteria from "../models/searchShipmentsCriteria";
import IUpdateCustomerModel from "../models/customers/updateCustomerModel";
import SearchCustomersCriteria from "../models/customers/searchCustomerCriteria";
import IAddCustomerStoreModel from "../models/customers/addStoreModel";
import IUpdateMemberModel from "../models/members/updateMemberModel";
import IUpdatePassword from "../models/customers/updatePasswordModel";
import IUpdateMemberPasswordModel from "../models/members/updateMemberPassword";
import DeactivateCutomerModel from "../models/customers/deactivateStoreModel";
import {SearchDraftCriteria} from "../models/DraftModel";
import IUpdateMemberRoleModel from "../models/members/updateMemberRoleModel";
import {SearchStationCriteria, StationsModel} from "../models/StationsModel";
import assignStationModel from "../models/members/assignStationModel";
import {CreateCityModel, IGenerateReportModel} from "../models/appStateModel";
import {IActionsSearchModel} from "../models/systemActions";
import {ISearchCitiesModel} from "../models/cities";
import CityModel from "../models/common/updateCityModel";
import ConfirmModel from "../models/customers/confirmModel";
import {EditStoreModel} from "../actions/customersActions";
import {TransferBalanceModel} from "../pages/Cod/transfer";
import {ClearBalanceModel} from "../pages/Cod/clear";
import {
    GenerateAllTransactionsReport,
    GenerateBalanceReportModel,
    MultiUsersCodReportsModel
} from "../models/codReports";
import {ContainersSearchModel} from "../models/containers";
import {client} from ".";
import {CityTiersModel} from "../models/cityTiersModel ";
import {SearchOtpCriteria} from "../models/otp";
import { NotificationModel, NotificationsRequest } from "../models/notifications";
import {CreatePartnerModel, SearchPartnerCriteria} from "../models/partner";
import { IReserveLockerRequest } from "../models/parcelat";


export const AssignAmsAddressToShipmentAsync = async (shipmentId: string, amsAddressId: string): Promise<ApiResponse> => {
    const res = await client.SimplyPostAsync("shipments/SetAmsReference", {
        shipmentId,
        amsAddressId
    })
    return res;
}
export const GenerateReport = (reportScheme: IGenerateReportModel) => {

    const res: ApiResponse = client.SimplyPostAsync("shipments/report", reportScheme)
    return res;
}
export const SearchShipment = async (
    request: SearchShipmentsCriteria,
    exportToExcel: boolean,
    partnerId?: number
) => {
    let limit = request.pageSize ?? 20;
    let pageNumber = request.pageNumber ?? 1;
    let offset = (pageNumber - 1) * limit!;
    let query = `limit=${limit}&offset=${offset}`;
    request.bypassStationFilter && (query += "&bypassStationFilter=true");
    request.search && (query += `&search=${request.search}`);
    request.numberContains && (query += `&number=${request.numberContains}`);
    request.referenceContains &&
    (query += `&uniquereference=${request.referenceContains}`);
    request.CustomerId && (query += `&customerId=${request.CustomerId}`);
    request.courier && (query += `&courierId=${request.courier}`);
    request.driver && (query += `&driverId=${request.driver}`);
    request.stationId && (query += `&stationId=${request.stationId}`);

    request.consigneeName && (query += `&consigneeName=${request.consigneeName}`);
    request.shipperName && (query += `&shipperName=${request.shipperName}`);

    request.consigneeCity && (query += `&consigneeCity=${request.consigneeCity}`);
    request.statusReason && (query += `&StatusReason=${request.statusReason}`);
    request.isNotConfirmedCustomer && (query += `&isNotConfirmedCustomer=${request.isNotConfirmedCustomer}`);
    partnerId && (query += `&partnerId=${partnerId}`);
    
    request.consigneeNumber &&
    (query += `&consigneeNumber=${request.consigneeNumber}`);

    if (
        request.statuses &&
        request.statuses.length > 0
    ) {
        request.statuses.forEach((status) => {
            if (status) {
                query += `&status=${status}`;
            }
        });
    }

    if (
        request.numbers &&
        request.numbers.length > 0
    ) {
        request.numbers.forEach((number) => {
            query += `&numbers=${number}`;
        });
    }

    if (request.codCollectionDateFrom && request.codCollectionDateTo) {
        query += `&CodCollectionDateFrom=${request.codCollectionDateFrom?.toISOString()}
        &CodCollectionDateTo=${(request.codCollectionDateTo)?.toISOString()}`;
    }
    if (request.createdFrom && request.createdTo) {
        query += `&fromDate=${request.createdFrom.toISOString()}
        &toDate=${request.createdTo.toISOString()}`;
    }
    if (request.deliveredFrom && request.deliveredTo) {
        query += `&deliveredFromDate=${request.deliveredFrom.toISOString()}
        &DeliveredToDate=${request.deliveredTo.toISOString()}`;
    }
    if (request.ReturnDateFrom && request.ReturnDateTo) {
        query += `&ReturnDateFrom=${request.ReturnDateFrom.toISOString()}
        &ReturnDateTo=${request.ReturnDateTo.toISOString()}`;
    }
    if (request.ReceivedDateFrom && request.ReceivedDateTo) {
        query += `&ReceivedFromDate=${request.ReceivedDateFrom.toISOString()}
        &ReceivedToDate=${request.ReceivedDateTo.toISOString()}`;
    }
    request.shippingMethod && (query += `&shippingMethod=${request.shippingMethod}`);

    if (exportToExcel) {
        const res: ApiResponse = await client.SimplyGetAsync(
            `shipments/export?${query}`
        );
        return res;
    }
    const res: ApiResponse = await client.SimplyGetAsync(`shipments?${query}`);
    return res;
};
export const SearchShipmentForPickup = async (
    request: SearchShipmentsCriteria,
    exportToExcel: boolean
) => {
    let limit = request.pageSize ?? 20;
    let pageNumber = request.pageNumber ?? 1;
    let offset = (pageNumber - 1) * limit!;
    let query = `limit=${limit}&offset=${offset}`
    if (request.statuses && request?.statuses?.length > 0) {
        request.statuses.forEach((status) => {
            query += `&status=${status}`;
        });

    } else {
        query = `limit=${limit}&offset=${offset}&status=1&status=2&status=5&status=10`;
    }

    request.bypassStationFilter && (query += "&bypassStationFilter=true");
    request.search && (query += `&search=${request.search}`);
    request.numberContains && (query += `&number=${request.numberContains}`);
    request.referenceContains &&
    (query += `&uniquereference=${request.referenceContains}`);
    request.CustomerId && (query += `&customerId=${request.CustomerId}`);
    request.courier && (query += `&courierId=${request.courier}`);
    request.driver && (query += `&driverId=${request.driver}`);
    request.stationId && (query += `&stationId=${request.stationId}`);

    request.consigneeName && (query += `&consigneeName=${request.consigneeName}`);
    request.consigneeCity && (query += `&consigneeCity=${request.consigneeCity}`);
    request.statusReason && (query += `&StatusReason=${request.statusReason}`);
    request.isNotConfirmedCustomer && (query += `&isNotConfirmedCustomer=${request.isNotConfirmedCustomer}`);
    request.shipperName && (query += `&shipperName=${request.shipperName}`);

    request.consigneeNumber &&
    (query += `&consigneeNumber=${request.consigneeNumber}`);


    if (request.codCollectionDateFrom && request.codCollectionDateTo) {
        query += `&CodCollectionDateFrom=${request.codCollectionDateFrom?.toISOString()}
        &CodCollectionDateTo=${(request.codCollectionDateTo)?.toISOString()}`;
    }
    if (request.createdFrom && request.createdTo) {
        query += `&fromDate=${request.createdFrom.toISOString()}
        &toDate=${request.createdTo.toISOString()}`;
    }
    if (request.deliveredFrom && request.deliveredTo) {
        query += `&deliveredFromDate=${request.deliveredFrom.toISOString()}
        &DeliveredToDate=${request.deliveredTo.toISOString()}`;
    }
    if (request.ReturnDateFrom && request.ReturnDateTo) {
        query += `&ReturnDateFrom=${request.ReturnDateFrom.toISOString()}
        &ReturnDateTo=${request.ReturnDateTo.toISOString()}`;
    }
    if (request.ReceivedDateFrom && request.ReceivedDateTo) {
        query += `&ReceivedFromDate=${request.ReceivedDateFrom.toISOString()}
        &ReceivedToDate=${request.ReceivedDateTo.toISOString()}`;
    }

    if (exportToExcel) {
        const res: ApiResponse = await client.SimplyGetAsync(
            `shipments/export?${query}`
        );
        return res;
    }
    const res: ApiResponse = await client.SimplyGetAsync(`shipments?${query}`);
    return res;
};

export const SearchShipmentForForcePickup = async (
    request: SearchShipmentsCriteria,
    exportToExcel: boolean
) => {
    let limit = request.pageSize ?? 20;
    let pageNumber = request.pageNumber ?? 1;
    let offset = (pageNumber - 1) * limit!;
    let query = `limit=${limit}&offset=${offset}`
    if (request.statuses && request?.statuses?.length > 0) {
        request.statuses.forEach((status) => {
            query += `&status=${status}`;
        });

    } else {
        query = `limit=${limit}&offset=${offset}&status=1&status=2`;
    }

    request.bypassStationFilter && (query += "&bypassStationFilter=true");
    request.search && (query += `&search=${request.search}`);
    request.numberContains && (query += `&number=${request.numberContains}`);
    request.referenceContains &&
    (query += `&uniquereference=${request.referenceContains}`);
    request.CustomerId && (query += `&customerId=${request.CustomerId}`);
    request.courier && (query += `&courierId=${request.courier}`);
    request.driver && (query += `&driverId=${request.driver}`);
    request.stationId && (query += `&stationId=${request.stationId}`);

    request.consigneeName && (query += `&consigneeName=${request.consigneeName}`);
    request.consigneeCity && (query += `&consigneeCity=${request.consigneeCity}`);
    request.statusReason && (query += `&StatusReason=${request.statusReason}`);
    request.isNotConfirmedCustomer && (query += `&isNotConfirmedCustomer=${request.isNotConfirmedCustomer}`);
    request.shipperName && (query += `&shipperName=${request.shipperName}`);

    request.consigneeNumber &&
    (query += `&consigneeNumber=${request.consigneeNumber}`);


    if (request.codCollectionDateFrom && request.codCollectionDateTo) {
        query += `&CodCollectionDateFrom=${request.codCollectionDateFrom?.toISOString()}
        &CodCollectionDateTo=${(request.codCollectionDateTo)?.toISOString()}`;
    }
    if (request.createdFrom && request.createdTo) {
        query += `&fromDate=${request.createdFrom.toISOString()}
        &toDate=${request.createdTo.toISOString()}`;
    }
    if (request.deliveredFrom && request.deliveredTo) {
        query += `&deliveredFromDate=${request.deliveredFrom.toISOString()}
        &DeliveredToDate=${request.deliveredTo.toISOString()}`;
    }
    if (request.ReturnDateFrom && request.ReturnDateTo) {
        query += `&ReturnDateFrom=${request.ReturnDateFrom.toISOString()}
        &ReturnDateTo=${request.ReturnDateTo.toISOString()}`;
    }
    if (request.ReceivedDateFrom && request.ReceivedDateTo) {
        query += `&ReceivedFromDate=${request.ReceivedDateFrom.toISOString()}
        &ReceivedToDate=${request.ReceivedDateTo.toISOString()}`;
    }

    if (exportToExcel) {
        const res: ApiResponse = await client.SimplyGetAsync(
            `shipments/export?${query}`
        );
        return res;
    }
    const res: ApiResponse = await client.SimplyGetAsync(`shipments?${query}`);
    return res;
};

export const Login = async (username: string, password: string) => {
    const pClient = ClientFactory({
        baseUrl: GetClientConfig().baseUrl,
    });

    let res: ApiResponse = await pClient.SimplyPostAsync("accounts/login", {
        email: username,
        password,
    });
    return res;
};
export const SearchDraft = async (request: SearchDraftCriteria) => {
    const pClient = ClientFactory({
        baseUrl: GetClientConfig().baseUrl,
    });
    let limit = request.pageSize ?? 20;
    let pageNumber = request.pageNumber ?? 1;
    let offset = (pageNumber - 1) * limit!;
    let query = `?limit=${limit}&offset=${offset}`;

    request.id && (query += `&id=${request.id}`);
    if (request.createdFrom && request.createdTo) {
        query += `&fromDate=${request.createdFrom.toISOString()}&toDate=${request.createdTo.toISOString()}`;
    }
    let res: ApiResponse = await client.SimplyGetAsync(`shipments/draft${query}`);
    return res;
};
export const CreateDraft = async (count: number) => {
    const pClient = ClientFactory({
        baseUrl: GetClientConfig().baseUrl,
    });
    let res: ApiResponse = await client.SimplyPostAsync(`shipments/draft`, {
        count: count,
    });
    return res;
};

export const SearchStations = async (request: SearchStationCriteria) => {
    let limit = request.pageSize ?? 20;
    let pageNumber = request.pageNumber ?? 1;
    let offset = (pageNumber - 1) * limit!;
    let query = `?limit=${limit}&offset=${offset}`;

    request.name && (query += `&name=${request.name}`);

    let res: ApiResponse = await client.SimplyGetAsync(`stations${query}`);
    return res;
};

export const SearchCities = async (request?: ISearchCitiesModel, isExport?: boolean) => {
    let limit = request?.pageSize ?? 20;
    let pageNumber = request?.pageNumber ?? 1;
    let offset = (pageNumber - 1) * limit!;
    let query = `?limit=${limit}&offset=${offset}&name=${request?.name ?? ""}&isServiced=${request?.isServiced ?? ""}&isExport=${isExport ?? false}`;

    let res: ApiResponse = await client.SimplyGetAsync(`meta/cities${query}`);
    return res;
};
export const CreateStation = async (station: StationsModel) => {
    let res: ApiResponse = await client.SimplyPostAsync(`stations`, station);
    return res;
};

export const AddTierCity = async (tierId: number, cityId: number) => {
    let res: ApiResponse = await client.SimplyPostAsync(`cities/addTier`, {tierId, cityId});
    return res;
};
export const CreateCity = async (data:CreateCityModel) => {
    let res: ApiResponse = await client.SimplyPostAsync(`cities`, data);
    return res;
};

export const CreatePartner = async (data:CreatePartnerModel) => {
    let res: ApiResponse = await client.SimplyPostAsync(`partners`, data);
    return res;
};

export const UpdateCity = async (station: CityModel) => {
    let res: ApiResponse = await client.SimplyPostAsync(`meta/updateCityStatus`, station);
    return res;
};

export const SwitchTenant = async (tenant: string) => {
    let res: ApiResponse = await client.SimplyPostAsync("accounts/switchtenant", {
        toTenant: tenant,
    });
    return res;
};

export const GetProfile = async () => {
    let res: ApiResponse = await client.SimplyGetAsync("accounts/profile");
    return res;
};

export const GetShipmentDetails = async (id: string) => {
    const res: ApiResponse = await client.SimplyGetAsync(`shipments/${id}`);

    return res;
};
export const GetShipmentDetailsWithReport = async (
    id: string,
    base64: boolean = false
) => {
    const res: ApiResponse = await client.SimplyGetAsync(
        `shipments/${id}?getreport=true&base64=${base64 ? "true" : "false"}`
    );

    return res;
};

export const PrintBulkLabelsAsync = async (
    shipmentIds: string[],
) => {
    const res: ApiResponse = await client.SimplyPostAsync(
        `shipments/PrintLabels`, {
            shipmentIds
        }
    );

    return res;
};

export const GetStatuses = async () => {
    let res: ApiResponse = await client.SimplyGetAsync("meta/shipmentstatus");
    return res;
};
export const GetCategories = async () => {
    let res: ApiResponse = await client.SimplyGetAsync("meta/Categories ");
    return res;
};

export const GetDeliveryServices = async () => {
    let res: ApiResponse = await client.SimplyGetAsync("meta/DeliveryServices ");
    return res;
};
export const GetTraceStatuses = async () => {
    let res: ApiResponse = await client.SimplyGetAsync("meta/tracestatus");
    return res;
};
export const GetStatusReasons = async () => {
    let res: ApiResponse = await client.SimplyGetAsync("meta/StatusReasons");
    return res;
};
export const GetCities = async () => {
    let res: ApiResponse = await client.SimplyGetAsync("meta/cities?lookup=true&IsServiced=true&limit=1000");
    return res;
};

export const GetPartners = async () => {
    let res: ApiResponse = await client.SimplyGetAsync("meta/partners");
    return res;
};

export const GetStations = async () => {
    let res: ApiResponse = await client.SimplyGetAsync("stations?lookup=true");
    return res;
};

export const GetJobRoles = async () => {
    let res: ApiResponse = await client.SimplyGetAsync(
        "meta/jobroles?lookup=true"
    );
    return res;
};

export const CreateShipment = async (shipment: any) => {
    let data: any[] = [{...shipment}];
    let res: ApiResponse = await client.SimplyPostAsync("shipments", data);
    return res;
};

export const CreateBulkShipments = async (shipments: any[]) => {
    let res: ApiResponse = await client.SimplyPostAsync("shipments", shipments);
    return res;
};


export const GetShipmentsToBeCollected = async (courierId: string, report?: boolean) => {

    let q = `courierId=${courierId}`
    report && (q += `&report=true`);

    let res: ApiResponse = await client.SimplyGetAsync(
        `shipments/cod?${q}`
    );

    return res;
};

export const CollectCod = async (ids: any, courierId: string) => {
    let data = {
        shipmentIds: ids,
        courierId: courierId,
    };
    let res: ApiResponse = await client.SimplyPostAsync("shipments/cod", data);
    return res;
};


export const CourierRunSheet = async (courierId: string, report: boolean) => {
    let res: ApiResponse = await client.SimplyGetAsync(
        `Shipments/runsheet?courierId=${courierId}&report=${
            report ? "true" : "false"
        }`
    );
    return res;
};

export const UploadFile = async (title: any, file: any) => {
    let formData = new FormData();
    // formData.append("title", title);
    formData.append("File", file);
    const publicClient = ClientFactory({
        baseUrl: GetClientConfig().baseUrl,
    });
    let res: ApiResponse = await publicClient.SimplyPostFormAsync(
        "files/upload",
        formData
    );

    return res;
};

export const MapShipmentsFromExcel = async (url: string, storeId?: string) => {
    let res: ApiResponse = await client.SimplyPostAsync("shipments/ExcelMap", {
        fileUrl: url,
        storeId
    });
    return res;
};

export const MapShipmentsWithoutStoreFromExcel = async (url: string) => {
    let res: ApiResponse = await client.SimplyPostAsync("shipments/ShipmentsWithoutStoreExcelMap", {
        fileUrl: url,
    });
    return res;
};

//Customers
export const SearchCustomers = async (
    request: SearchCustomersCriteria,
    exportToExcel: boolean
) => {
    let query = `limit=${request.pageSize}&offset=${
        (request.pageNumber! - 1) * request.pageSize!
    }`;

    request.phoneContains && (query += `&phoneContains=${request.phoneContains}`);
    request.nameContains && (query += `&nameContains=${request.nameContains}`);
    request.emailContains && (query += `&emailContains=${request.emailContains}`);
    if (exportToExcel) {
        const res: ApiResponse = await client.SimplyGetAsync(
            `customers/export?${query}`
        );
        return res;
    }
    if (request.lookup) {
        const res: ApiResponse = await client.SimplyGetAsync(
            `customers?lookup=true`
        );
        return res;
    }
    const res: ApiResponse = await client.SimplyGetAsync(`customers?${query}`);
    return res;
};
export const GetCustomer = async (customerId: string) => {
    const res: ApiResponse = await client.SimplyGetAsync(
        `customers/${customerId}`
    );
    return res;
};

export const DeactivateCourier = async (id: string, deactivate: true) => {
    let res: ApiResponse = await client.SimplyPostAsync("accounts/deactivate", {
        accountId: id,
    });
    return res;
};
export const ActivateCourier = async (id: string, deactivate: true) => {
    let res: ApiResponse = await client.SimplyPostAsync("accounts/Activate", {
        accountId: id,
    });
    return res;
};
export const CreateCustomer = async (customer: any) => {
    let res: ApiResponse = await client.SimplyPostAsync("customers", customer);
    return res;
};
export const UpdateCustomer = async (
    id: string,
    data: IUpdateCustomerModel
) => {
    let res: ApiResponse = await client.SimplyPostAsync(`customers/${id}`, data);
    return res;
};
export const ConfirmCustomer = async (
    id: string,
    data: ConfirmModel
) => {
    let res: ApiResponse = await client.SimplyPostAsync(`customers/${id}/Confirm`, data);
    return res;
};
export const UpdatePassword = async (id: string, data: IUpdatePassword) => {
    let res: ApiResponse = await client.SimplyPostAsync(
        `accounts/${id}/updatePassword`,
        data
    );
    return res;
};
export const CreateCustomerStore = async (
    id: string,
    data: IAddCustomerStoreModel
) => {
    let res: ApiResponse = await client.SimplyPostAsync(
        `customers/${id}/store`,
        data
    );
    return res;
};
export const GenerateCustomerApiKey = async (id: string) => {
    let res: ApiResponse = await client.SimplyPostAsync(`accounts/apikey`, {
        customerId: id,
    });
    return res;
};
export const DeactivateCutomer = async (i: DeactivateCutomerModel) => {
    let res: ApiResponse = await client.SimplyPostAsync(
        `customers/DeactivateStore`,
        i
    );
    return res;
};
export const EditStore = async (updateStoreModel: EditStoreModel, id: string) => {
    let res: ApiResponse = await client.SimplyPostAsync(
        `customers/${id}/UpdateStore`,
        updateStoreModel
    );
    return res;
};
export const Revoke = async (id: string) => {
    let res: ApiResponse = await client.SimplyPostAsync(`accounts/RevokeApiKey`, {
        customerId: id,
    });
    return res;
};

//Members
export const SearchMembers = async (request: SearchMembersCriteria) => {
    !request.pageNumber && (request.pageNumber = 1);
    !request.pageSize && (request.pageSize = 100);

    let query = `accounts${request.exportToExcel ? "/export" : ""}?limit=${request.pageSize}&offset=${
        (request.pageNumber! - 1) * request.pageSize!
    }`;
    if (request.lookup) query = query + `&Lookup=${request.lookup}`;
    if (request.jobRole) query = query + `&jobRole=${request.jobRole}`;
    if (request.deactivated != undefined || request.deactivated != null)
        query = query + `&deactivated=${request.deactivated}`;
    if (request.nameContains)
        query = query + `&nameContains=${request.nameContains}`;
    if (request.phoneContains)
        query = query + `&phoneContains=${request.phoneContains}`;
    if (request.emailContains)
        query = query + `&emailContains=${request.emailContains}`;

    let res: ApiResponse = await client.SimplyGetAsync(query);

    return res;
};
export const CreateMember = async (member: any) => {
    let res: ApiResponse = await client.SimplyPostAsync("accounts", member);
    return res;
};
export const UpdateMember = async (id: string, data: IUpdateMemberModel) => {
    let res: ApiResponse = await client.SimplyPostAsync(`accounts/${id}`, data);
    return res;
};
export const UpdateRole = async (data: IUpdateMemberRoleModel) => {
    let res: ApiResponse = await client.SimplyPostAsync(`accounts/roles`, data);
    return res;
};
export const AssignStation = async (data: assignStationModel) => {
    let res: ApiResponse = await client.SimplyPostAsync(
        `accounts/AssignUserStation`,
        data
    );
    return res;
};
export const UpdateMemberPassword = async (
    id: string,
    data: IUpdateMemberPasswordModel
) => {
    let res: ApiResponse = await client.SimplyPostAsync(
        `accounts/${id}/updatePassword`,
        data
    );
    return res;
};

export const GetDashboardStatuses = async (from?: string, to?: string) => {
    const res: ApiResponse = await client.SimplyGetAsync(
        `dashboard/status`
    );
    return res;
};
export const ForceUpdateStatus = async (shipmentId: string, status: number) => {
    const res: ApiResponse = await client.SimplyPostAsync(
        `Operations/UpdateStatus`,
        {shipmentId, status}
    );
    return res;
};

export const GetDashboardCouriers = async (exportToExcel?: boolean) => {
    const res: ApiResponse = await client.SimplyGetAsync(
        `dashboard/courier?exportToExcel=${exportToExcel ?? false}`
    );
    return res;
};

export const GetCodData = async (model: GenerateBalanceReportModel) => {
    const res: ApiResponse = await client.SimplyGetAsync(
        `balance/reports?exportToExcel=${model.exportToExcel ?? ''}&filterType=${model.filterType ?? ''}&&filterId=${model.filterId ?? ''}&dateFrom=${model.dateFrom?.toUTCString() ?? ''}&dateTo=${model.dateTo?.toUTCString() ?? ''}&totalReport=${model.totalReport ?? false}&transactionsReport=${model.transactionsReport ?? false}`
    );
    return res;
};

export const GetMemberMultipleCodData = async (model: MultiUsersCodReportsModel) => {
    const res: ApiResponse = await client.SimplyPostAsync(
        `balance/MultiMemberCodReports`,
        model
    );
    return res;
};
export const GetTransactionsExcelReport = async (model: GenerateAllTransactionsReport) => {
    const res: ApiResponse = await client.SimplyGetAsync(
        `balance/Transactions?ExportToExcel=${model.exportToExcel ?? ''}&balanceType=${model.balanceType ?? ''}&memberAccountId=${model.memberAccountId ?? ''}&dateFrom=${model.dateFrom?.toUTCString() ?? ''}&dateTo=${model.dateTo?.toUTCString() ?? ''}&reason=${model.reason ?? ''}`
    );
    return res;
};
export const GetCustomerMultipleCodData = async (model: MultiUsersCodReportsModel) => {
    const res: ApiResponse = await client.SimplyPostAsync(
        `balance/MultiCustomerReports`,
        model
    );
    return res;
};
export const GetCodUserData = async (model: GenerateBalanceReportModel) => {
    const res: ApiResponse = await client.SimplyGetAsync(
        `balance/accountReports?exportToExcel=${model.exportToExcel ?? ''}&filterId=${model.filterId ?? ''}&dateFrom=${model.dateFrom?.toUTCString() ?? ''}&dateTo=${model.dateTo?.toUTCString() ?? ''}&transactionsReport=${model.transactionsReport ?? false}`
    );
    return res;
};

export const GetDashboardShipments = async (status: string | null) => {
    let query = `dashboard/shipments${status != null ? `?status=${status}` : ''}`
    const res: ApiResponse = await client.SimplyGetAsync(
        query
    );
    return res;
};


export const getSystemActionsAsync = async (request: IActionsSearchModel, exportToExcel?: boolean): Promise<ApiResponse> => {

    let pageNumber = request.pageNumber ?? 1;
    let offset = (pageNumber - 1) * 20;
    let query = `Dashboard/actions?offset=${offset}`
    request.customerId && (query += `&customerId=${request.customerId}`);
    request.actionType && (query += `&ActionType=${request.actionType}`);
    request.actionById && (query += `&ActionById=${request.actionById}`);
    request.stationId && (query += `&stationId=${request.stationId}`);
    request.consigneeCity && (query += `&consigneeCity=${request.consigneeCity}`);
    request.shipperCity && (query += `&shipperCity=${request.shipperCity}`);
    request.isAutomatic != undefined && (query += `&isAutomatic=${request.isAutomatic}`);


    if (request.from && request.to) {
        query += `&fromDate=${request.from.toISOString()}&toDate=${request.to.toISOString()}`;
    }
    if (exportToExcel) {
        query += "&ExportToExcel=true"
    }
    const res: ApiResponse = await client.SimplyGetAsync(
        query
    );
    return res;

}

export const TransferData = async (data: TransferBalanceModel) => {
    let res: ApiResponse = await client.SimplyPostAsync(
        `balance/transfer`, data
    );
    return res;
};

export const ClearData = async (data?: ClearBalanceModel) => {
    let res: ApiResponse = await client.SimplyPostAsync(
        `balance/clear`, data
    );
    return res;
};


export const SearchContainers = async (request: ContainersSearchModel): Promise<ApiResponse> => {
    let limit = 20;
    let pageNumber = request.pageNumber ?? 1;
    let offset = (pageNumber - 1) * limit!;
    let query = `limit=${limit}&offset=${offset}`;
    const res: ApiResponse = await client.SimplyGetAsync(
        `Containers?${query}`
    );
    return res;
};
export const CreateContainer = async (transferTo: number,): Promise<ApiResponse> => {
    const res: ApiResponse = await client.SimplyPostAsync(
        `Containers/`, {journeyId: transferTo}
    );
    return res;
};

export const AddContainment = async (containerId: number, shipmentIds: string[]): Promise<ApiResponse> => {
    const res: ApiResponse = await client.SimplyPostAsync(
        `Containers/${containerId}/AddContainment`,
        {
            shipmentIds
        }
    );
    return res;
};
export const ValidateAddContainment = async (containerId: number, shipmentIds: string[]): Promise<ApiResponse> => {
    const res: ApiResponse = await client.SimplyPostAsync(
        `Containers/${containerId}/ValidateAddContainment`,
        {
            shipmentIds
        }
    );
    return res;
};
export const CloseContainer = async (containerId: number): Promise<ApiResponse> => {
    const res: ApiResponse = await client.SimplyPostAsync(
        `Containers/${containerId}/Close`,
        {}
    );
    return res;
};
export const CheckInContainer = async (containerId: number, stationId: number): Promise<ApiResponse> => {
    const res: ApiResponse = await client.SimplyPostAsync(
        `Containers/${containerId}/CheckIn`,
        {stationId}
    );
    return res;
};
export const CheckOutContainer = async (containerId: number, stationId: number): Promise<ApiResponse> => {
    const res: ApiResponse = await client.SimplyPostAsync(
        `Containers/${containerId}/CheckOut`,
        {stationId}
    );
    return res;
};
export const RemoveContainment = async (containerId: number, shipmentId: string): Promise<ApiResponse> => {
    const res: ApiResponse = await client.SimplyPostAsync(
        `Containers/${containerId}/RemoveContainment`,
        {
            shipmentId
        }
    );
    return res;
};

export const ContainersExportToExcel = async (): Promise<ApiResponse> => {
    const res: ApiResponse = await client.SimplyGetAsync(
        `Containers/export`
    );
    return res;
};

export const ContainerTracesExportToExcel = async (id: number): Promise<ApiResponse> => {
    const res: ApiResponse = await client.SimplyGetAsync(
        `Containers/${id}/export`
    );
    return res;
};
export const CityTiers = async () => {

    let res: ApiResponse = await client.SimplyGetAsync(`tiers`);
    return res;
};
export const CreateCityTier = async (tier: CityTiersModel) => {
    let res: ApiResponse = await client.SimplyPostAsync(`tiers`, tier);
    return res;
};

export const SearchOtp = async (request?: SearchOtpCriteria) => {
    let limit = request?.pageSize ?? 20;
    let pageNumber = request?.pageNumber ?? 1;
    let offset = (pageNumber - 1) * limit!;
    let query = `?limit=${limit}&offset=${offset}&shipmentNumber=${request?.shipmenNumber ?? ""}`;
    let res: ApiResponse = await client.SimplyGetAsync(`Otp${query}`);
    return res;
};

export const SearchPartner = async (request?: SearchPartnerCriteria) => {
    let limit = request?.pageSize ?? 20;
    let pageNumber = request?.pageNumber ?? 1;
    let offset = (pageNumber - 1) * limit!;
    let query = `?limit=${limit}&offset=${offset}&email=${request?.email ?? ""}&name=${request?.name ?? ""}`;
    let res: ApiResponse = await client.SimplyGetAsync(`partners${query}`);
    return res;
};

export const SearchNotifications = async (request?: NotificationsRequest) => {
    let limit = request?.pageSize ?? 20;
    let pageNumber = request?.pageNumber ?? 1;
    let offset = (pageNumber - 1) * limit!;
    let query = `limit=${limit}&offset=${offset}`;
    let res: ApiResponse = await client.SimplyGetAsync(`Notifications?${query}`);
    return res;
};

export const CreateNotificatin = async (notification: NotificationModel) => {
    let res: ApiResponse = await client.SimplyPostAsync(`notifications`, notification);
    return res;
};

export const SearchCustomerNotifications = async (request?: NotificationsRequest) => {
    let limit = request?.pageSize ?? 20;
    let pageNumber = request?.pageNumber ?? 1;
    let offset = (pageNumber - 1) * limit!;
    let query = `limit=${limit}&offset=${offset}`;
    let res: ApiResponse = await client.SimplyGetAsync(`notifications/CustomerNotifications?${query}`);
    return res;
};

export const SearchParcelatMachines = async () => {
    let res: ApiResponse = await client.SimplyGetAsync(`parcelat/getAvailableMachines`);
    return res;
};

export const SearchLockersSizes = async () => {
    let res: ApiResponse = await client.SimplyGetAsync(`shipments/getLockersSizes`);
    return res;
};

export const ReserveLocker = async (request?: IReserveLockerRequest) => {
    let res: ApiResponse = await client.SimplyPostAsync(`parcelat/reserveLocker`, request);
    return res;
}

export const DeliverToHome = async (request?: {shipmentId: string}) => {
    let res: ApiResponse = await client.SimplyPostAsync(`parcelat/deliverToHome`, request);
    return res;
}

export const CheckIfUserHasChoosen = async (request: {shipmentId: string}) => {
    let res: ApiResponse = await client.SimplyGetAsync(`parcelat/userChoosed?shipmentId=${request?.shipmentId}`);
    return res;
}

export const GetShippingMethods = async () => {
    let res: ApiResponse = await client.SimplyGetAsync("meta/ShippingMethod");
    return res;
};

export const GetParcelatUsers = async () => {
    let res: ApiResponse = await client.SimplyGetAsync("parcelat/GetMyParcelatUsers");
    return res;
}

export const ChnageParcelatCourier = async (request: {reservationId: string; newUser: string}) => {
    let res: ApiResponse = await client.SimplyPostAsync(`Parcelat/EditParcelatCourier`, request);
    return res;
}