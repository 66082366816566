import AppStateModel from "../models/appStateModel";

import InitialAppState from "./initialState";

import {
    AUTHENTICATION_FAILED,
    AUTHENTICATION_SUCCEEDED,
    MEMBER_INFO_RETRIEVED,
    SET_ALERT,
    SET_AMS_CITIES,
    SET_AMS_CRITERIA,
    SET_CITIES,
    SET_CITIES_CRITERIA, SET_CITY_TIERS,
    SET_CONTAINERS,
    SET_CONTAINERS_CRITERIA,
    SET_COURIER_RUNSHEET,
    SET_CUSTOMER,
    SET_CUSTOMERS,
    SET_CUSTOMERS_CRITERIA,
    SET_DRAFTS,
    SET_DRAFTS_CRITERIA,
    SET_JOURNEYS,
    SET_LOADING,
    SET_LOCALE,
    SET_MEMBERS,
    SET_MEMBERS_CRITERIA,
    SET_META,
    SET_OTP,
    SET_OTP_CRITERIA,
    SET_ROUTES,
    SET_SELECTED_SHIPMENT,
    SET_SHIPMENTS,
    SET_SHIPMENTS_CRITERIA,
    SET_STATIONS,
    SET_STATIONS_CRITERIA,
    UNAUTHENTICATE,
    SET_NOTIFICATONS,
    SET_NOTIFICATONS_REQUEST,
    SET_CUSTOMER_NOTIFICATONS, 
    SET_PARTNER, SET_PARTNER_CRITERIA,
    SET_PARCELAT_MACHINES

} from "./types";

const appReducer = (state = InitialAppState, action: any): AppStateModel => {
    switch (action.type) {
        case SET_AMS_CITIES:
            return {
                ...state,
                AMS: {
                    ...state.AMS,
                    Cities: action.payload
                },
            };
        case SET_JOURNEYS:
            return {
                ...state,
                Journeys: action.payload,
            };
        case SET_ROUTES:
            return {
                ...state,
                Routes: action.payload,
            };

        case SET_LOCALE:
            return {
                ...state,
                Locale: action.payload.locale,
                AppContent: action.payload.appContent,
            };

        case SET_LOADING:
            return {
                ...state,
                Loading: {
                    isLoading: action.payload.isLoading,
                    message: action.payload.message,
                },
            };
        case SET_ALERT:
            return {
                ...state,
                Loading: {
                    isLoading: false,
                    message: "",
                },
                Alert: action.payload,
            };
        case SET_CUSTOMER:
            return {
                ...state,
                SelectedCustomer: action.payload.customer,
                Loading: {
                    isLoading: false,
                    message: "",
                },

            };

        case SET_COURIER_RUNSHEET:
            return {
                ...state,
                Loading: {
                    isLoading: false,
                    message: "",
                },
                courierRunSheet: {
                    courierId: action.payload.courierId,
                    shipments: action.payload.shipments,
                },
            };

        case UNAUTHENTICATE:
            return {
                ...state,
                Loading: {
                    isLoading: false,
                },
                Auth: {
                    accessToken: null,
                    refreshToken: null,
                    isAuthenticated: false,
                },
                Profile: {
                    isComplete: false
                }
            };

        case AUTHENTICATION_SUCCEEDED:
            return {
                ...state,
                Loading: {
                    isLoading: false,
                },
                Auth: {
                    accessToken: action.payload.accessToken,
                    refreshToken: action.payload.refreshToken,
                    isAuthenticated: true,
                }
            };
        case AUTHENTICATION_FAILED:
            return {
                ...state,
                Loading: {
                    isLoading: false,
                },
                Auth: {
                    isAuthenticated: false,
                    accessToken: null,
                    refreshToken: null,
                },
                Profile: {
                    isComplete: false,
                },
            };

        case MEMBER_INFO_RETRIEVED:
            return {
                ...state,
                Loading: {
                    isLoading: false,
                    message: "",
                },
                Profile: {
                    ...action.user,
                    isComplete: true
                },
            };

        case SET_SHIPMENTS:
            return {
                ...state,
                Loading: {
                    isLoading: false,
                },
                PagedShipments: action.payload.searchShipmentsResult,
            };

        case SET_SHIPMENTS_CRITERIA:
            return {
                ...state,
                SearchShipmentsCriteria: {
                    ...state.SearchShipmentsCriteria,
                    ...action.payload.filters,
                },
            };
        case SET_SELECTED_SHIPMENT:
            return {
                ...state,
                Loading: {
                    isLoading: false,
                },
                SelectedShipment: action.payload.shipment,
            };

        case SET_META:
            return {
                ...state,
                Meta: {
                    ...state.Meta,
                    ...action.payload,
                },
            };
        case SET_CUSTOMERS:
            return {
                ...state,
                Loading: {
                    isLoading: false,
                },
                PagedCustomers: action.payload.customers,
            };

        case SET_CUSTOMERS_CRITERIA:
            return {
                ...state,
                SearchCustomersCriteria: {
                    ...state.SearchCustomersCriteria,
                    ...action.payload.filters,
                },
            };
        case SET_DRAFTS_CRITERIA:
            return {
                ...state,
                DraftBatchesCriteria: {
                    ...state.DraftBatchesCriteria,
                    ...action.payload.filters,
                },
            };
        case SET_STATIONS_CRITERIA:
            return {
                ...state,
                StationsCriteria: {
                    ...state.StationsCriteria,
                    ...action.payload.filters,
                },
            };
        case SET_MEMBERS:
            return {
                ...state,
                Loading: {
                    isLoading: false,
                },
                PagedMembers: action.payload.searchMembersResult,
            };
        case SET_DRAFTS:
            return {
                ...state,
                Loading: {
                    isLoading: false,
                },
                PagedDraftBatches: action.payload.searchDraftsResult,
            };
        case SET_STATIONS:
            return {
                ...state,
                Loading: {
                    isLoading: false,
                },
                PagedStations: action.payload.searchStationsResult,
            };
        case SET_CITIES:
            return {
                ...state,
                Loading: {
                    isLoading: false,
                },
                PagedCities: action.payload.searchCitiesResult,
            };
        case SET_CITIES_CRITERIA:
            return {
                ...state,
                SearchCitiesCriteria: {
                    ...state.SearchCitiesCriteria,
                    ...action.payload.filters,
                },
            };
        case SET_AMS_CRITERIA:
            return {
                ...state,
                SearchAmsCriteria: {
                    ...state.SearchAmsCriteria,
                    ...action.payload.filters,
                },
            };
        case SET_MEMBERS_CRITERIA:
            return {
                ...state,
                SearchMembersCriteria: {
                    ...state.SearchMembersCriteria,
                    ...action.payload.filters,
                },
            };
        case SET_CONTAINERS:
            return {
                ...state,
                Loading: {
                    isLoading: false,
                },
                PagedContainers: action.payload.searchContainersResult,
            };
        case SET_CONTAINERS_CRITERIA:
            return {
                ...state,
                SearchContainersCriteria: {
                    ...state.SearchContainersCriteria,
                    ...action.payload.filters,
                },
            };
        case SET_OTP:
            return {
                ...state,
                Loading: {
                    isLoading: false,
                },
                PagedOtp: action.payload.searchOtpResult,
            };
        case SET_PARTNER:
            return {
                ...state,
                Loading: {
                    isLoading: false,
                },
                PagedPartner: action.payload.searchPartnerResult,
            };
        case SET_OTP_CRITERIA:
            return {
                ...state,
                SearchOtpCriteria: {
                    ...state.SearchOtpCriteria,
                    ...action.payload.filters,
                },
            };
        case SET_PARTNER_CRITERIA:
            return {
                ...state,
                SearchPartnerCriteria: {
                    ...state.SearchPartnerCriteria,
                    ...action.payload.filters,
                },
            };
        case SET_CITY_TIERS:
            return {
                ...state,
                Loading: {
                    isLoading: false,
                },
                CityTiers: action.payload,
            };
        case SET_NOTIFICATONS:
            return {
                ...state,
                SearchNotifications: action.payload,
            };
        case SET_NOTIFICATONS_REQUEST:
            return {
                ...state,
                NotificationsRequest: action.payload,
            }
        case SET_CUSTOMER_NOTIFICATONS:
            return {
                ...state,
                SearchCustomerNotifications: action.payload,
            };
        case SET_PARCELAT_MACHINES:
            return {
                ...state,
                ParcelatMachines: action.payload,
            };
        default:
            return state;
    }
};

export default appReducer;
