import React, { useEffect, useMemo, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";
import { Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import AssignLockerOnMap from "./AssignOnMap";
import { SearchParcelatMachinesAsync } from "../../actions/parcelatActions";
import { useDispatch, useSelector } from "react-redux";
import AppStateModel from "../../models/appStateModel";
import { IMachinesMappedData, machinesMapper } from "../../models/parcelat";
import { ReserveLocker } from "../../api/data";
import { SetAlert } from "../../actions/common";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const ChooseLockerModal = ({ onClose, isOpen }: IProps) => {
  const [lockerInfo, setLockerInfo] = useState<IMachinesMappedData>({});

  const dispatch = useDispatch();
  const location = useLocation();

  const parcelatMachines = useSelector(
    (state: AppStateModel) => state.ParcelatMachines
  );

  useEffect(() => {
    dispatch(SearchParcelatMachinesAsync());
  }, [dispatch]);

  const mappedMachines = useMemo(() => {
    return machinesMapper(parcelatMachines?.machines?.result);
  }, [parcelatMachines]);

  const getShipmentId = () => {
    const params = new URLSearchParams(location.search);
    return params.get("shipmentId") ?? "";
  };
  
  const handleSubmit = async () => {
    const shipmentId = getShipmentId();
    const res = await ReserveLocker({ machineName: lockerInfo.name, shipmentId });

    if (res.succeeded) {
      dispatch(
        SetAlert({
          visible: true,
          data: "",
          title: "Success",
          kind: "success",
        })
      );
    } else {
      dispatch(
        SetAlert({
          visible: true,
          data: res.data,
          title: "Error",
          kind: "error",
        })
      );
    }
    
    onClose();
  };
    
  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      dialogClassName="modal-100w"
      aria-labelledby="example-custom-modal-styling-title"
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          {"Click On A Location Icon To Choose A Locker"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card>
          <Card.Body style={{ height: "70vh" }}>
            {lockerInfo ? (
              <div>
                {lockerInfo.displayName && <p><strong>Locker Name:</strong> {lockerInfo.displayName}</p>}
                {lockerInfo.address1 && <p><strong>Address:</strong> {lockerInfo.address1}</p>}
                {lockerInfo.street && <p><strong>Street Name:</strong> {lockerInfo.street}</p>}
                {lockerInfo.googleUrl && <p><strong>Google Maps URL:</strong> <a href={lockerInfo.googleUrl} target="_blank" rel="noopener noreferrer">View on Maps</a></p>}
              </div>
            ) : (
              <p>Select a locker to see details</p>
            )}
            <AssignLockerOnMap
              setLockerInfo={setLockerInfo}
              machines={mappedMachines}
              mapHeight={lockerInfo?.name ? "70%" : "90%"}
            />
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose}>Close</Button>
        <Button onClick={handleSubmit} disabled={!lockerInfo.name}>Submit</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChooseLockerModal;
